<!--Footer Starts-->
<footer class="footer">
  <!-- <p class="copyright clearfix text-muted m-0">
    Copyright &copy; {{currentDate | date: 'yyyy'}} <a id="pixinventLink"
      href="https://themeforest.net/user/pixinvent/portfolio?ref=pixinvent">PIXINVENT</a>
    <span class="d-none d-sm-inline-block">, All rights reserved.</span>
  </p> -->

  <p class="copyright clearfix text-muted m-0">
    <a id="pixinventLink"
      href="https://odres.com.mx/">oDres SA de CV {{currentDate | date: 'yyyy'}}</a>
  </p>
</footer>
<!--Footer Ends-->
