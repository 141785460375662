<!-- Sidebar Header starts -->
<div class="sidebar-header" *ngIf="authService.usuarioConectado != null && authService.usuarioConectado.TemplateConf.layout.menuPosition === 'Side'">
  <div class="logo clearfix">
    <a [routerLink]="['/empresa/informacion-empresa']" class="logo-text float-left">
      <img [src]="authService.usuarioConectado.ListaImagenesEmpresa[0].esImagenEmpresa ? authService.usuarioConectado.ListaImagenesEmpresa[0].Ruta : authService.usuarioConectado.ListaImagenesEmpresa[1].Ruta" class="avatar mr-2" alt="Avatar" width="40" height="40" style="margin-left: -5px">
      <!-- <div class="logo-img">
        <img [src]="logoUrl" alt="Apex logo"/>
      </div> -->
      <span class="text align-middle ng-tns-c6-2 ng-star-inserted" 
      *ngIf="authService.usuarioConectado != null && authService.usuarioConectado.Empresa != null"
      style="font-size: 12pt; display: inline-block; width: 150px; height: 50px; overflow: hidden; white-space: initial;"       
      >
      {{authService.usuarioConectado.Empresa.Nombre}}</span>
    </a>
    <a class="nav-toggle d-none d-lg-none d-xl-block" id="sidebarToggle" href="javascript:;"
      (click)="toggleSidebar()">
      <i #toggleIcon [ngClass]="claseToggle" class="toggle-icon"></i>
    </a>
    <a class="nav-close d-block d-lg-block d-xl-none" id="sidebarClose" href="javascript:;" (click)="CloseSidebar()">
      <i class="ft-x"></i>
    </a>
  </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div class="sidebar-content main-menu-content" [perfectScrollbar]  [disabled]="!perfectScrollbarEnable">
  <div class="nav-container" *ngIf="authService.usuarioConectado != null">
    <ul class="navigation" appSidebarDropdown>
      <!-- First level menu -->
      <li appSidebarlink *ngFor="let menuItem of authService.usuarioConectado.MenuItems" 
      [parent]="menuItem.title" [path]="menuItem.path" level="{{level + 1}}" 
      [hasSub]="menuItem.classs.includes('has-sub') ? true: false"
        [ngClass]="{'has-sub': menuItem.classs.includes('has-sub') ? true: false}"
        [routerLinkActive]="menuItem.submenu.length != 0 ? 'open' : 'active'" [routerLinkActiveOptions]="{exact: false}">
        <a appSidebarAnchorToggle *ngIf="menuItem.submenu.length > 0 && !menuItem.isExternalLink">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title" [class.menuMasGrande]="menuItem.title.length > 20 ? 'menuMasGrande' : null" >{{menuItem.title | translate }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
        <a appSidebarAnchorToggle routerLink="{{menuItem.path}}"
          *ngIf="menuItem.submenu.length === 0 && !menuItem.isExternalLink">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{menuItem.title | translate }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
        <a [href]="[menuItem.path]" target="_blank" *ngIf="menuItem.isExternalLink">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{menuItem.title | translate }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
         <!-- Second level menu -->
        <ul appSidebarDropdown class="menu-content" *ngIf="menuItem.submenu.length > 0 ">
          <li appSidebarlink *ngFor="let menuSubItem of menuItem.submenu" [parent]="menuItem.title" 
          [hasSub]="menuSubItem.classs.includes('has-sub') ? true: false"
          [path]="menuSubItem.path" level="{{level + 2}}" [ngClass]="{'has-sub': menuSubItem.classs.includes('has-sub') ? true: false}"
            [routerLinkActive]="menuSubItem.submenu.length != 0 ? 'open' : 'active'">
            <a appSidebarAnchorToggle *ngIf="menuSubItem.submenu.length > 0 && !menuSubItem.isExternalLink">
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span class="menu-title">{{menuSubItem.title | translate }}</span>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
            </a>
            <a appSidebarAnchorToggle routerLink="{{menuSubItem.path}}"
              *ngIf="menuSubItem.submenu.length === 0 && !menuSubItem.isExternalLink">
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span class="menu-title">{{menuSubItem.title | translate }}</span>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
            </a>
            <a routerLink="{{menuSubItem.path}}" target="_blank" *ngIf="menuSubItem.isExternalLink">
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span class="menu-title">{{menuSubItem.title | translate }}</span>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
            </a>
            <!-- Third level menu -->
            <ul appSidebarDropdown class="menu-content" *ngIf="menuSubItem.submenu.length > 0">
              <li appSidebarlink *ngFor="let menuSubsubItem of menuSubItem.submenu" [parent]="menuItem.title" [hasSub]="false"
              [path]="menuSubsubItem.path" level="{{level + 3}}" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                [ngClass]="config.layout.menuPosition === 'Side' ? menuSubsubItem.classs : ''">
                <a appSidebarAnchorToggle routerLink="{{menuSubsubItem.path}}"
                  *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock">
                  <i [ngClass]="[menuSubsubItem.icon]"></i>
                  <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                  <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' "
                    [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                </a>
                <ng-template #externalSubSubLinkBlock>
                  <a routerLink="{{menuSubsubItem.path}}" target="_blank">
                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                    <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                    <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' "
                      [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                  </a>
                </ng-template>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
<!-- Sidebar Content Ends -->
