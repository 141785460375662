import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'busqueda-catalogos',
    loadChildren: () => import('../../oDres/modulos/catalogos/paginas/busquedas/busquedas-catalogos.module')
                              .then(m => m.BusquedasCatalogosModule)
  },
  {
    path: 'generales',
    loadChildren: () => import('../../oDres/modulos/generales/paginas/generales.module').then(m => m.GeneralesModule)
  },
  {
    path: 'catalogos',
    loadChildren: () => import('../../oDres/modulos/catalogos/paginas/catalogos/catalogos.module').then(m => m.CatalogosModule)
  },
  {
    path: 'rh-nomina',
    loadChildren: () => import('../../oDres/modulos/rh-nomina/paginas/rh-nomina.module').then(m => m.RhNominaModule)
  },
  {
    path: 'sistema',
    loadChildren: () => import('../../oDres/modulos/sistema/paginas/sistema.module').then(m => m.SistemaModule)
  },
  {
    path: 'bancos',
    loadChildren: () => import('../../oDres/modulos/bancos/paginas/bancos.module').then(m => m.BancosModule)
  },
  {
    path: 'contabilidad',
    loadChildren: () => import('../../oDres/modulos/contabilidad/paginas/contabilidad.module').then(m => m.ContabilidadModule)
  },
  {
    path: 'ventas-ingresos',
    loadChildren: () => import('../../oDres/modulos/ventas-ingresos/paginas/ventas-ingresos.module').then(m => m.VentasIngresosModule)
  },
  {
    path: 'inventarios',
    loadChildren: () => import('../../oDres/modulos/inventarios/paginas/inventarios.module').then(m => m.InventariosModule)
  },
  {
    path: 'compras-egresos',
    loadChildren: () => import('../../oDres/modulos/compras-egresos/paginas/compras-egresos.module').then(m => m.ComprasEgresosModule)
  },
  {
    path: 'empresa',
    loadChildren: () => import('../../oDres/modulos/empresa/paginas/empresa.module').then(m => m.EmpresaModule)
  },
  {
    path: 'cuentas-por-pagar',
    loadChildren: () => import('../../oDres/modulos/cuentas-pagar/paginas/cuentas-pagar.module').then(m => m.CuentasPagarModule)
  },
  {
    path: 'cuentas-por-cobrar',
    loadChildren: () => import('../../oDres/modulos/cuentas-cobrar/paginas/cuentas-cobrar.module').then(m => m.CuentasCobrarModule)
  },  
  {
    path: 'crm',
    loadChildren: () => import('../../oDres/modulos/crm/paginas/crm.module').then(m => m.CrmModule)
  },  
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'dashboards',
    loadChildren: () => import('../../oDres/modulos/dashboards/paginas/dashboards.module').then(m => m.DashboardsModule)
  },
  {
    path: 'reportes',
    loadChildren: () => import('../../oDres/modulos/reportes/paginas/reportes.module').then(m => m.ReportesModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('../../calendar/calendar.module').then(m => m.CalendarsModule)
  },
  {
    path: 'charts',
    loadChildren: () => import('../../charts/charts.module').then(m => m.ChartsNg2Module)
  },
   {
    path: 'forms',
    loadChildren: () => import('../../forms/forms.module').then(m => m.FormModule)
  },
  {
    path: 'tables',
    loadChildren: () => import('../../tables/tables.module').then(m => m.TablesModule)
  },
  {
    path: 'datatables',
    loadChildren: () => import('../../data-tables/data-tables.module').then(m => m.DataTablesModule)
  },
  {
    path: 'uikit',
    loadChildren: () => import('../../ui-kit/ui-kit.module').then(m => m.UIKitModule)
  },
  {
    path: 'components',
    loadChildren: () => import('../../components/ui-components.module').then(m => m.UIComponentsModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
  },
  {
    path: 'cards',
    loadChildren: () => import('../../cards/cards.module').then(m => m.CardsModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('../../chat/chat.module').then(m => m.ChatModule)
  },
  {
    path: 'chat-ngrx',
    loadChildren: () => import('../../chat-ngrx/chat-ngrx.module').then(m => m.ChatNGRXModule)
  },
  {
    path: 'inbox',
    loadChildren: () => import('../../inbox/inbox.module').then(m => m.InboxModule)
  },
  {
    path: 'taskboard',
    loadChildren: () => import('../../taskboard/taskboard.module').then(m => m.TaskboardModule)
  },
  {
    path: 'taskboard-ngrx',
    loadChildren: () => import('../../taskboard-ngrx/taskboard-ngrx.module').then(m => m.TaskboardNGRXModule)
  }
];
