import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Usuario } from 'app/oDres/modelos/Empresa/usuarios';
import { AuthService } from 'app/shared/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as fromApp from '../../store/app.reducer';
import * as R from 'ramda';
import swal, { SweetAlertOptions } from 'sweetalert2';
import { Router } from '@angular/router';
import { Chats } from 'app/oDres/modelos/Generales/chats';
import { MensajesChat } from 'app/oDres/modelos/Generales/mensajesChat';
import { ChatGrupal } from 'app/oDres/modelos/Generales/chatGrupal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Chat } from 'app/oDres/modelos/Generales/chat';
import { UsersChat } from 'app/chat-ngrx/chat-ngrx.model';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { UsuarioAgrupado } from 'app/oDres/modelos/Empresa/usuarioAgrupado';
import { GeneralesService } from 'app/oDres/modulos/generales/datos/generales.service';
import { CustomizerService } from '../services/customizer.service';
import { ConfigService } from '../services/config.service';


@Component({
  selector: 'app-chat-emergente',
  templateUrl: './chat-emergente.component.html',
  styleUrls: ['./chat-emergente.component.scss']
})
export class ChatEmergenteComponent implements OnInit {

  activeChat: UsersChat;
  isContentOverlay = false;
  newMessage = "";
  chats: Chat[] = [];
  searchQuery: string = '';
  placement = "bottom-right";
  loggedInUserImg: string;

  messages = new Array();  

  tempData = [];
  tempDataBuscar = [];

  nuevoChatGrupal: Chats = {};

  listaUsuariosAgrupados: UsuarioAgrupado[] = [];
  listaUsuariosChat: Usuario[] = [];
  listaBuscarUsuariosChat: Usuario[] = [];

  imageChangedEvent: any = '';
  hasAnotherDropZoneOver = false;
  fileName = '';
  croppedImage: any = '';
  showCropper = false;
  closeResult: string;

  canvasRotation = 0;
  transform: ImageTransform = {};
  rotation = 0;
  scale = 1;

  modalReference: any;

  indexUsuarioConectado = 0;

  showArrow = false;

  showEmojiPicker = false;

  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ];

  set = 'twitter';

  @ViewChild('chatEmergente') chatEmergente: ElementRef;

  public config: any = {};

  size: string;
  
  constructor(private store: Store<fromApp.AppState>, 
    private spinner: NgxSpinnerService, 
    private generalesService: GeneralesService,
    public authService: AuthService,
    private router: Router, private renderer: Renderer2,
    private modalService: NgbModal,
    private configService: ConfigService) { 

      this.config = this.configService.templateConf;
      this.authService.isChatOpen = !this.config.layout.customizer.hidden;

      if (this.config.layout.sidebar.size) {
        this.size = this.config.layout.sidebar.size;
      }

    }

  ngOnInit(): void {

    // this.spinner.show();

    // this.generalesService.obtenerUsuario({IdEmpresa: this.authService.usuarioConectado.Empresa.IdEmpresa, Activo: true} as Usuario).subscribe(respuestaUsuarios => {

    //   console.log(respuestaUsuarios);

    //   if (respuestaUsuarios != null && respuestaUsuarios.code == 200) {

    //     if (respuestaUsuarios.model.length > 0) {

    //       let indexUsuarioConectado = respuestaUsuarios.model.findIndex(u => u.IdUsuario == this.authService.usuarioConectado.Usuario.IdUsuario);

    //       respuestaUsuarios.model.splice(indexUsuarioConectado, 1);

    //       // respuestaUsuarios.model.forEach((usuario, i) => {

    //       //   usuario.chat.isActiveChat = false;
      
    //       // });

    //       this.listaBuscarUsuariosChat = respuestaUsuarios.model.Resultado;
    //       this.tempDataBuscar = R.clone(respuestaUsuarios.model);

    //       this.generalesService.obtenerChatsUsuario(this.authService.usuarioConectado.Empresa.IdEmpresa, this.authService.usuarioConectado.Usuario.IdUsuario).subscribe(respuestaChats => { 

    //         console.log(respuestaChats);

    //         if (respuestaChats != null && respuestaChats.code == 200) {

    //           this.authService.chats = respuestaChats.model;

    //           this.authService.chats.forEach(chat => {

    //             chat.isActiveChat = false;                
    //             chat.mostrarMenuChat = false;
          
    //           });

    //           this.spinner.hide();

    //         }

    //       });          

    //     } else {

    //       const opciones: SweetAlertOptions = {
    //         icon: 'warning',
    //         title: 'Sin usuarios',
    //         text: 'No se encontraron mas usuarios en la empresa',
    //         type: 'warning',
    //         allowOutsideClick: false,
    //         allowEscapeKey: false,
    //       } as SweetAlertOptions;

    //       this.spinner.hide();

    //       swal.fire(opciones);

    //       this.router.navigate([this.authService.usuarioConectado.Perfil.UrlPaginaInicio]);

    //     }

    //   } else {

    //     if (respuestaUsuarios.code == 204) {

    //       const opciones: SweetAlertOptions = {
    //         icon: 'warning',
    //         title: 'Sin usuarios',
    //         text: 'No se encontraron mas usuarios en la empresa',
    //         type: 'warning',
    //         allowOutsideClick: false,
    //         allowEscapeKey: false,
    //       } as SweetAlertOptions;

    //       this.spinner.hide();

    //       swal.fire(opciones);

    //       this.router.navigate([this.authService.usuarioConectado.Perfil.UrlPaginaInicio]);

    //     } else {

    //       const opciones: SweetAlertOptions = {
    //         icon: 'warning',
    //         title: 'Notificación',
    //         text: respuestaUsuarios.message,
    //         type: 'warning',
    //         allowOutsideClick: false,
    //         allowEscapeKey: false,
    //       } as SweetAlertOptions;

    //       this.spinner.hide();

    //       swal.fire(opciones);

    //       this.router.navigate([this.authService.usuarioConectado.Perfil.UrlPaginaInicio]);

    //     }

    //   }

    // });

  }

  onSidebarToggle() {
    this.isContentOverlay = true;
  }

  onContentOverlay() {
    this.isContentOverlay = false;
  }
  
  viewChatUsuarioBusqueda(usuario: Usuario) {

    console.log(usuario);

    let indexChat = this.authService.chats.findIndex(u => u.IdUsuarioDestino == usuario.IdUsuario);

    if (indexChat > 0){

      this.authService.chats[indexChat].isActiveChat = true;

      this.authService.chatActivo = this.authService.chats[indexChat];
  
      this.authService.chatActivo.unreadMessageCount = null;

      this.indexUsuarioConectado = this.authService.chatActivo.listaUsuariosChat.findIndex(u => u.IdUsuario.toLocaleLowerCase() == this.authService.usuarioConectado.Usuario.IdUsuario.toLocaleLowerCase());
      
    } else {

      let listaUsuarios = [];

      listaUsuarios.push({IdUsuario: this.authService.usuarioConectado.Usuario.IdUsuario, NombreCompleto: this.authService.usuarioConectado.Usuario.NombreCompleto} as Usuario);
      listaUsuarios.push({IdUsuario: usuario.IdUsuario, NombreCompleto: usuario.NombreCompleto} as Usuario);

      this.authService.chatActivo = {

        IdUsuarioDestino: usuario.IdUsuario,
        Urlimagen: usuario.Urlimagen,
        NombreChat: usuario.NombreCompleto,
        isActiveChat: true,
        mensajes: [],
        listaUsuariosChat: listaUsuarios,
        listaAdministradores: [],
        esChatGrupal: false
        
      };

      this.indexUsuarioConectado = 0;

    }

    this.authService.chats.forEach(chat => {

      chat.isActiveChat = false;      
      chat.mostrarMenuChat = false;

    });
    
    this.isContentOverlay = false;

    this.newMessage = '';

    this.searchQuery = '';

    console.log(this.authService.chatActivo);

    // let indexUsuario = this.authService.usuariosChat.findIndex(u => u.IdUsuario == usuario.IdUsuario);

    // this.authService.usuariosChat.forEach((usuario, i) => {

    //   usuario.chat.isActiveChat = false;

    // });

    // this.authService.usuariosChat[indexUsuario].chat.isActiveChat = true;

    // this.authService.usuarioActivo = this.authService.usuariosChat[indexUsuario];

    // this.authService.chatActivo.unreadMessageCount = null;

    // this.isContentOverlay = false;

    // this.newMessage = '';

    // //this.messageInput.nativeElement.focus();

  }

  viewChat(chat: Chat) {

    console.log(chat);

    let indexChat = this.authService.chats.findIndex(u => u.IdChat == chat.IdChat);

    this.authService.chats.forEach(chat => {

      chat.isActiveChat = false;      
      chat.mostrarMenuChat = false;

    });

    this.authService.chats[indexChat].isActiveChat = true;    

    this.authService.chatActivo = this.authService.chats[indexChat];

    this.authService.chatActivo.unreadMessageCount = null;   
    
    this.indexUsuarioConectado = this.authService.chatActivo.listaUsuariosChat.findIndex(u => u.IdUsuario.toLocaleLowerCase() == this.authService.usuarioConectado.Usuario.IdUsuario.toLocaleLowerCase());
    
    this.isContentOverlay = false;

    this.newMessage = '';

    // let indexUsuario = this.authService.usuariosChat.findIndex(u => u.IdUsuario == usuario.IdUsuario);

    // this.authService.usuariosChat.forEach((usuario, i) => {

    //   usuario.chat.isActiveChat = false;

    // });

    // this.authService.usuariosChat[indexUsuario].chat.isActiveChat = true;

    // this.authService.usuarioActivo = this.authService.usuariosChat[indexUsuario];

    // this.authService.chatActivo.unreadMessageCount = null;

    // this.isContentOverlay = false;

    // this.newMessage = '';

    // //this.messageInput.nativeElement.focus();

  }

  //send button function calls
  onAddMessage() {

    let mensaje: MensajesChat = {};

    if (this.newMessage != "") {

      this.authService.chatActivo.listaUsuariosChat.forEach(usuario => {

        if (usuario.mensajes == null || usuario.mensajes.length == 0){        

          usuario.mensajes = [];

          mensaje = {
            IdUsuarioEscribe: this.authService.usuarioConectado.Usuario.IdUsuario,
            UsuarioEscribe: this.authService.usuarioConectado.Usuario.NombreCompleto,
            Urlimagen: this.authService.usuarioConectado.Usuario.Urlimagen,
            isReceived: false,
            time: "",
            mensajes: [this.newMessage],
            tipoMensaje: 'text'
          };
  
          usuario.mensajes.push(mensaje);
        } else {
  
          if (usuario.mensajes[usuario.mensajes.length - 1].IdUsuarioEscribe 
            == this.authService.usuarioConectado.Usuario.IdUsuario) {
  
              usuario.mensajes[usuario.mensajes.length - 1].mensajes.push(this.newMessage);
  
              //mensaje = this.authService.chatActivo.mensajes[this.authService.chatActivo.mensajes.length - 1];
  
          } else {
  
            mensaje = {
              IdUsuarioEscribe: this.authService.usuarioConectado.Usuario.IdUsuario,
              UsuarioEscribe: this.authService.usuarioConectado.Usuario.NombreCompleto,
              Urlimagen: this.authService.usuarioConectado.Usuario.Urlimagen,
              isReceived: false,
              time: "",
              mensajes: [this.newMessage],
              tipoMensaje: 'text'
            };
  
            usuario.mensajes.push(mensaje);
  
          }
  
        }

        usuario.JsonMensajes = JSON.stringify(usuario.mensajes);

      });
    
      const mensajeEnviado = this.newMessage;
      this.newMessage = '';
      this.authService.chatActivo.lastChatMessage = mensajeEnviado;   

      // Aqui ir a base de datos

      let indexChat = this.authService.chats.findIndex(u => u.IdUsuarioDestino == this.authService.chatActivo.IdUsuarioDestino);

      if (indexChat == -1){

        this.authService.chats.push(this.authService.chatActivo);
        
      }
      
      this.generalesService.agregarActualizarChats(
        {           
          IdChat: this.authService.chatActivo.IdChat,
          IdEmpresa: this.authService.usuarioConectado.Empresa.IdEmpresa, 
          IdUsuario: this.authService.usuarioConectado.Usuario.IdUsuario,           
          ultimaHora: new Date().toLocaleString(),
          ultimoMensaje: mensajeEnviado,
          listaUsuariosChat: this.authService.chatActivo.listaUsuariosChat,
          esChatGrupal: this.authService.chatActivo.esChatGrupal
        } as Chats).subscribe(respuestaChats => {        

          console.log(respuestaChats);

          if (this.authService.chatActivo.IdChat == null){
            let indexChat = this.authService.chats.findIndex(u => u.IdUsuarioDestino == this.authService.chatActivo.IdUsuarioDestino);

            this.authService.chats[indexChat].IdChat = respuestaChats.model.IdChat;
  
            this.authService.chatActivo.IdChat = respuestaChats.model.IdChat;

            this.spinner.show();
            this.spinner.hide();
          }                    
      }); 
      
      console.log(this.authService.chatActivo);
    }
  }

  abrirModalCrearGrupo(content){

    this.toggleCustomizer();

    this.croppedImage = '';

    this.nuevoChatGrupal = {UrlImagen: './assets/FotoUser.png', listaUsuariosChat: [], listaAdministradores: [], IdEmpresa: this.authService.usuarioConectado.Empresa.IdEmpresa, esChatGrupal: true};

    this.spinner.show();

    this.generalesService.obtenerUsuario({IdEmpresa: this.authService.usuarioConectado.Empresa.IdEmpresa, Activo: true} as Usuario).subscribe(respuestaUsuarios => {

      console.log(respuestaUsuarios);

      if (respuestaUsuarios != null && respuestaUsuarios.code == 200){  
        
        let indexUsuarioConectado = respuestaUsuarios.model.findIndex(u => u.IdUsuario == this.authService.usuarioConectado.Usuario.IdUsuario);

        respuestaUsuarios.model.splice(indexUsuarioConectado, 1);
        
        this.listaUsuariosChat = respuestaUsuarios.model;
        this.tempData = this.listaUsuariosChat;

        this.listaUsuariosChat.forEach(usuario => {

          usuario.seleccionado = false;
          usuario.administradorChat = false;

        });

        this.modalService.open(content, { backdrop: 'static', centered: true, windowClass:'crearGrupoChat' });

        // this.generalesService.inicializarCatalogo(this.listaUsuarioDirigido, (e: Usuario) => this.usuarioDirigidoSeleccionado = e,
        // 'IdUsuario', 'NombreCompleto', 'Usuarios');
        // respuestaUsuarios.model.Resultado.forEach((d: Usuario) => this.listaUsuarioDirigido.push(d));
        // this.filteredListaUsuarioDirigido = this._filteredListaUsuarioDirigido();

        // if (this.generalesService.tareaService != null && this.generalesService.tareaService.IdTarea != null){
        //   this.tarea = this.generalesService.tareaService;
        // }
      }

      this.spinner.hide();

      });    
  }

  crearNuevoGrupo(){

    if (this.nuevoChatGrupal.listaAdministradores.findIndex(u => u.IdUsuario == this.authService.usuarioConectado.Usuario.IdUsuario) == -1){
      this.nuevoChatGrupal.listaAdministradores.push({IdUsuario: this.authService.usuarioConectado.Usuario.IdUsuario} as Usuario);
    }  
    
    if (this.nuevoChatGrupal.listaUsuariosChat.findIndex(u => u.IdUsuario == this.authService.usuarioConectado.Usuario.IdUsuario) == -1){
      this.nuevoChatGrupal.listaUsuariosChat.push({IdUsuario: this.authService.usuarioConectado.Usuario.IdUsuario} as Usuario);
    }  

    if (this.croppedImage != '') {
      
      this.nuevoChatGrupal.Documento = {
        nombreArchivo: 'imagenesgruposchat/' + this.fileName,
        contenedor: this.authService.usuarioConectado.Empresa.Identificador.toLocaleLowerCase().trim(),
        esImagen: true,
        archivo: this.croppedImage.replace('data:image/png;base64,', '')
      };

    }

    this.spinner.show();

    this.generalesService.agregarActualizarChats(this.nuevoChatGrupal).subscribe(respuestaChatGrupal => {

      console.log(respuestaChatGrupal);

      if (respuestaChatGrupal != null && respuestaChatGrupal.code == 200){

        this.generalesService.obtenerChatsUsuario(this.authService.usuarioConectado.Empresa.IdEmpresa, this.authService.usuarioConectado.Usuario.IdUsuario).subscribe(respuestaChats => { 

          console.log(respuestaChats);
    
          if (respuestaChats != null && respuestaChats.code == 200) {

            this.authService.chats = respuestaChats.model;

            if (this.authService.chats.length > 0){
              this.authService.chats.forEach(chat => {

                chat.isActiveChat = false;
          
              });
  
              let indexChat = this.authService.chats.findIndex(c => c.IdChat == respuestaChatGrupal.model.IdChat);
  
              this.authService.chats[indexChat].isActiveChat = true;
  
              this.authService.chatActivo = this.authService.chats[indexChat];
            }            

            this.isContentOverlay = false;

            const opciones: SweetAlertOptions = {
              icon: 'success',
              title: 'Grupo creado',
              text: "Grupo creado exitosamente",
              type: 'success',
              allowOutsideClick: false,
              allowEscapeKey: false
            } as SweetAlertOptions;
    
            swal.fire(opciones);
    
            this.modalService.dismissAll();

            this.toggleCustomizer();

          }

        });

        // this.authService.chats.forEach(chat => {

        //   chat.isActiveChat = false;
    
        // });

        // this.authService.chats.push(
        //   { 
        //     IdChat: respuestaChatGrupal.model.IdChat,            
        //     NombreChat: this.nuevoChatGrupal.NombreGrupo,
        //     Urlimagen: respuestaChatGrupal.model.UrlImagen,
        //     esChatGrupal: true,
        //     isActiveChat: true,
        //     statusChat: "online",
        //     isPinnedUser: false,
        //     isMuted: false,
        //     mensajes: []
        //   } as Chat
        // );

        // let indexChat = this.authService.chats.findIndex(c => c.IdChat == respuestaChatGrupal.model.IdChat);

        // this.authService.chatActivo = this.authService.chats[indexChat];

        // this.isContentOverlay = false;

        // const opciones: SweetAlertOptions = {
        //   icon: 'success',
        //   title: 'Grupo creado',
        //   text: "Grupo creado exitosamente",
        //   type: 'success',
        //   allowOutsideClick: false,
        //   allowEscapeKey: false
        // } as SweetAlertOptions;

        // swal.fire(opciones);

        // this.modalService.dismissAll();

      } else {

        const opciones: SweetAlertOptions = {
          icon: 'error',
          title: 'Error',
          text: respuestaChatGrupal.message,
          type: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false
        } as SweetAlertOptions;

        swal.fire(opciones);

      }

      this.spinner.hide();

    });

  }

  editarImagenGrupo(content) {

   this.modalReference = this.modalService.open(content, {backdrop: 'static', windowClass:'editarAvatar', centered: true });

  }

  NombreEmpleadoFiltro(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.tempData.filter(function (d: Usuario) {
      return d.NombreCompleto.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.listaUsuariosChat = temp;
  }

  seleccionoUsuario(e, usuario){
    console.log(usuario);

    let indexUsuario = this.listaUsuariosChat.findIndex(r => r.IdUsuario == usuario.IdUsuario);     

    if (e === true) { 

      this.nuevoChatGrupal.listaUsuariosChat.push({IdUsuario: usuario.IdUsuario, IdEmpresa: this.authService.usuarioConectado.Usuario.IdUsuario} as Usuario);

      this.listaUsuariosChat[indexUsuario].seleccionado = true;            

    } else {

      let indexUsuario = this.nuevoChatGrupal.listaUsuariosChat.findIndex(u => u.IdUsuario == usuario.IdUsuario);

      this.nuevoChatGrupal.listaUsuariosChat.splice(indexUsuario, 1);

      this.listaUsuariosChat[indexUsuario].seleccionado = false;

    }

    console.log(this.nuevoChatGrupal);
  }

  seleccionoUsuarioAdministrador(e, usuario){
    console.log(usuario);

    let indexUsuario = this.listaUsuariosChat.findIndex(r => r.IdUsuario == usuario.IdUsuario);     

    if (e === true) { 

      this.nuevoChatGrupal.listaAdministradores.push({IdUsuario: usuario.IdUsuario, IdEmpresa: this.authService.usuarioConectado.Usuario.IdUsuario} as Usuario);

      this.listaUsuariosChat[indexUsuario].administradorChat = true;            

    } else {

      let indexUsuario = this.nuevoChatGrupal.listaAdministradores.findIndex(u => u.IdUsuario == usuario.IdUsuario);

      this.nuevoChatGrupal.listaAdministradores.splice(indexUsuario, 1);

      this.listaUsuariosChat[indexUsuario].administradorChat = false;

    }

    console.log(this.nuevoChatGrupal);
  }

  NombreUsuarioFiltro(event) {
    console.log('NombreUsuarioFiltro');

    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.tempDataBuscar.filter(function (d: Usuario) {
      return d.NombreCompleto.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.listaBuscarUsuariosChat = temp;

    console.log(this.searchQuery);
  }

  fileOverBase(e: any): void {
    // this.hasBaseDropZoneOver = e;
    this.imageChangedEvent = event;
  }

  fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  fileChangeEvent(event: any): void {
    this.fileName = event.target.files[0].name;
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady() {
      // cropper ready
  }

  loadImageFailed() {
      // show message
  }

  cancelCropping() {
    this.imageChangedEvent = null;
    this.showCropper = false;
  }

  getFile(event: any){
    console.log('file: ', event.target.files[0]);

    this.fileName = event.target.files[0].name;
    this.imageChangedEvent = event;
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
      this.canvasRotation++;
      this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
        ...this.transform,
        flipH: flippedV,
        flipV: flippedH
    };
  }

  flipHorizontal() {
    this.transform = {
        ...this.transform,
        flipH: !this.transform.flipH
    };
  }

  flipVertical() {
      this.transform = {
          ...this.transform,
          flipV: !this.transform.flipV
      };
  }

  updateRotation() {
    this.transform = {
        ...this.transform,
        rotate: this.rotation
    };
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
  }

  zoomIn() {
      this.scale += .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  revisarRotation(){
    console.log(this.rotation);

    if (this.rotation == undefined || this.rotation == null) {
      this.rotation = 0;
    }
  }

  zoomChange(){
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  revisarZoom(){
    console.log(this.scale);

    if (this.scale == undefined || this.scale == null) {
      this.scale = 1;
    }
  }

  guardarImagen(){
    this.modalReference.close();
  }

  cancelarGuardarFoto() {
    this.croppedImage = '';
    this.modalReference.close();
  }

  cerrarNuevoGrupo(){
    this.croppedImage = '';
    this.modalService.dismissAll();
    this.toggleCustomizer();
  }

  mostrarMenuChat(chat){
    console.log('mostrarMenuEmergente');

    let indexChat = this.authService.chats.findIndex(c => c.IdChat == chat.IdChat);

    this.authService.chats[indexChat].mostrarMenuChat = true;
  }

  eliminarChat(chat){
    console.log('eliminarChat');

    let indexChat = this.authService.chats.findIndex(c => c.IdChat == chat.IdChat);    

    // aqui ir a base para eliminar el json de mensajes

    this.spinner.show();

    this.generalesService.eliminarChats({           
      IdChat: chat.IdChat,
      IdEmpresa: this.authService.usuarioConectado.Empresa.IdEmpresa, 
      IdUsuario: this.authService.usuarioConectado.Usuario.IdUsuario,
      listaUsuariosChat: this.authService.chatActivo.listaUsuariosChat,
    } as Chats).subscribe(resultadoEliminarChat => {

      console.log(resultadoEliminarChat);

      if (resultadoEliminarChat != null && resultadoEliminarChat.code == 200){
        this.authService.chats.splice(indexChat, 1);  
        
        if (this.authService.chats.length == 0){
          this.authService.chatActivo = null;
        }else{
          this.authService.chats[0].isActiveChat = true;
          this.authService.chatActivo = this.authService.chats[0];
          // if (this.authService.chats.length == 1){
          //   this.authService.chats[0].isActiveChat = true;
          //   this.authService.chatActivo = this.authService.chats[0];
          // } else {
          //   if (indexChat == 0) {
          //     this.authService.chats[indexChat+1].isActiveChat = true;
          //     this.authService.chatActivo = this.authService.chats[indexChat+1];
          //   } else {
          //     this.authService.chats[indexChat-1].isActiveChat = true;
          //     this.authService.chatActivo = this.authService.chats[indexChat-1];
          //   }           
          // }
                   
        }

        this.spinner.hide();
      }      

    });

    //this.authService.chats[indexChat].mostrarMenuChat = true;
  }

  toggleEmojiPicker(){
    this.showEmojiPicker = !this.showEmojiPicker;  
  }

  addEmoji(event) {
    console.log(this.newMessage)
    const { newMessage } = this;
    console.log(newMessage);
    console.log(`${event.emoji.native}`)
    const text = `${newMessage}${event.emoji.native}`;

    this.newMessage = text;
    // this.showEmojiPicker = false;
  }

  abrirModalEmoticons(content){
    this.modalService.open(content, { backdrop: 'static', centered: true, windowClass:'modalEmoticons' });
  }

  cerrarModalEmoticons(){
    this.modalService.dismissAll();
  }

  abrirModalUsuarios(content){

    this.toggleCustomizer();

    this.spinner.show();

    this.generalesService.obtenerUsuarioAgrupado(this.authService.usuarioConectado.Empresa.IdEmpresa, this.authService.usuarioConectado.Usuario.IdUsuario, true).subscribe(respuestaUsuarios => {

      console.log(respuestaUsuarios);

      if (respuestaUsuarios != null && respuestaUsuarios.code == 200){              
        
        this.listaUsuariosAgrupados = respuestaUsuarios.model;

        this.listaUsuariosAgrupados.forEach(agrupado => {

          agrupado.verDetalle = false;
          agrupado.esVisible = true; 
                  
        }); 

        // this.tempData = this.listaUsuariosChat;

        // this.listaUsuariosChat.forEach(usuario => {

        //   usuario.seleccionado = false;
        //   usuario.administradorChat = false;

        // });

        this.modalService.open(content, { backdrop: 'static', centered: true, windowClass:'crearGrupoChat' });       
      }

      this.spinner.hide();

      });    
  }

  cerrarModalUsuarios(){
    this.modalService.dismissAll();
    this.toggleCustomizer();
  }

  verDetalle(indexDetalle) {

    if (this.listaUsuariosAgrupados[indexDetalle].verDetalle) {
      this.listaUsuariosAgrupados[indexDetalle].verDetalle = false;

      this.listaUsuariosAgrupados.forEach(row => {
     
        row.esVisible = true;
                  
    });
    } else {
      this.listaUsuariosAgrupados[indexDetalle].verDetalle = true;

      this.listaUsuariosAgrupados.forEach((row, index) => {

        if (index != indexDetalle) {
          row.esVisible = false;
        }            
  
      });
    }        

  }

  seleccionarUsuarioChat(usuario){
    console.log(usuario);

    let indexChat = this.authService.chats.findIndex(u => u.IdUsuarioDestino == usuario.IdUsuario);

    if (indexChat > 0){

      this.authService.chats[indexChat].isActiveChat = true;

      this.authService.chatActivo = this.authService.chats[indexChat];
  
      this.authService.chatActivo.unreadMessageCount = null;

      this.indexUsuarioConectado = this.authService.chatActivo.listaUsuariosChat.findIndex(u => u.IdUsuario.toLocaleLowerCase() == this.authService.usuarioConectado.Usuario.IdUsuario.toLocaleLowerCase());
      
    } else {

      let listaUsuarios = [];

      listaUsuarios.push({IdUsuario: this.authService.usuarioConectado.Usuario.IdUsuario, NombreCompleto: this.authService.usuarioConectado.Usuario.NombreCompleto} as Usuario);
      listaUsuarios.push({IdUsuario: usuario.IdUsuario, NombreCompleto: usuario.NombreCompleto} as Usuario);

      this.authService.chatActivo = {

        IdUsuarioDestino: usuario.IdUsuario,
        Urlimagen: usuario.Urlimagen,
        NombreChat: usuario.NombreCompleto,
        isActiveChat: true,
        mensajes: [],
        listaUsuariosChat: listaUsuarios,
        listaAdministradores: [],
        esChatGrupal: false
        
      };

      this.indexUsuarioConectado = 0;

    }

    this.authService.chats.forEach(chat => {

      chat.isActiveChat = false;      
      chat.mostrarMenuChat = false;

    });
    
    this.isContentOverlay = false;

    this.newMessage = '';

    this.searchQuery = '';

    console.log(this.authService.chatActivo);

    this.modalService.dismissAll();
  }

  toggleCustomizer() {

    if (this.authService.isChatOpen) {
      this.renderer.removeClass(this.chatEmergente.nativeElement, 'open');
      this.authService.isChatOpen = false;
    } else {

      // Aqui ir por las cosas del on init
      this.spinner.show();

      this.generalesService.obtenerUsuario({IdEmpresa: this.authService.usuarioConectado.Empresa.IdEmpresa, Activo: true} as Usuario).subscribe(respuestaUsuarios => {

        console.log(respuestaUsuarios);

        if (respuestaUsuarios != null && respuestaUsuarios.code == 200) {

          if (respuestaUsuarios.model.length > 0) {

            let indexUsuarioConectado = respuestaUsuarios.model.findIndex(u => u.IdUsuario == this.authService.usuarioConectado.Usuario.IdUsuario);

            respuestaUsuarios.model.splice(indexUsuarioConectado, 1);

            // respuestaUsuarios.model.forEach((usuario, i) => {

            //   usuario.chat.isActiveChat = false;
        
            // });

            this.listaBuscarUsuariosChat = respuestaUsuarios.model.Resultado;
            this.tempDataBuscar = R.clone(respuestaUsuarios.model);

            this.generalesService.obtenerChatsUsuario(this.authService.usuarioConectado.Empresa.IdEmpresa, this.authService.usuarioConectado.Usuario.IdUsuario).subscribe(respuestaChats => { 

              console.log(respuestaChats);

              if (respuestaChats != null && respuestaChats.code == 200) {

                this.authService.chats = respuestaChats.model;

                if (this.authService.chats.length > 0){
                  this.authService.chats.forEach(chat => {

                    chat.isActiveChat = false;                
                    chat.mostrarMenuChat = false;
              
                  });

                  this.authService.chats[0].isActiveChat = true;
                  this.authService.chatActivo = this.authService.chats[0];

                } else {
                  this.authService.chatActivo = null;
                }
                
                this.renderer.addClass(this.chatEmergente.nativeElement, 'open');
                this.authService.isChatOpen = true;

                this.spinner.hide();

              }

            });          

          } else {

            const opciones: SweetAlertOptions = {
              icon: 'warning',
              title: 'Sin usuarios',
              text: 'No se encontraron mas usuarios en la empresa',
              type: 'warning',
              allowOutsideClick: false,
              allowEscapeKey: false,
            } as SweetAlertOptions;

            this.spinner.hide();

            swal.fire(opciones);

            this.router.navigate([this.authService.usuarioConectado.Perfil.UrlPaginaInicio]);

          }

        } else {

          if (respuestaUsuarios.code == 204) {

            const opciones: SweetAlertOptions = {
              icon: 'warning',
              title: 'Sin usuarios',
              text: 'No se encontraron mas usuarios en la empresa',
              type: 'warning',
              allowOutsideClick: false,
              allowEscapeKey: false,
            } as SweetAlertOptions;

            this.spinner.hide();

            swal.fire(opciones);

            this.router.navigate([this.authService.usuarioConectado.Perfil.UrlPaginaInicio]);

          } else {

            const opciones: SweetAlertOptions = {
              icon: 'warning',
              title: 'Notificación',
              text: respuestaUsuarios.message,
              type: 'warning',
              allowOutsideClick: false,
              allowEscapeKey: false,
            } as SweetAlertOptions;

            this.spinner.hide();

            swal.fire(opciones);

            this.router.navigate([this.authService.usuarioConectado.Perfil.UrlPaginaInicio]);

          }

        }

      });

      
    }
  }

  closeCustomizer() {
    this.renderer.removeClass(this.chatEmergente.nativeElement, 'open');
    this.authService.isChatOpen = false;
  }

}
