import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2
} from '@angular/core';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { CustomizerService } from '../services/customizer.service';
import { AuthService } from '../auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { FileUploader } from 'ng2-file-upload';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

import * as R from 'ramda';
import { EmpresaConfiguracionImagenes } from 'app/oDres/modelos/Empresa/empresaConfiguracionImagenes';
import { Documento } from 'app/oDres/modelos/Empresa/documento';
import { LocalStorageService } from 'angular-web-storage';

@Component({
  selector: 'app-customizer',
  templateUrl: './customizer.component.html',
  styleUrls: ['./customizer.component.scss']
})
export class CustomizerComponent implements OnInit, OnDestroy {
  @ViewChild('customizer') customizer: ElementRef;

  size: string;
  isBgImageDisplay = true;
  isOpen = true;
  public config: any = {};
  layoutSub: Subscription;

  uploader: FileUploader = new FileUploader({
    url: URL,
    isHTML5: true
  });

  hasBaseDropZoneOver = false;
  hasAnotherDropZoneOver = false;

  showCropper = false;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  fileName = '';

  closeResult: string;

  canvasRotation = 0;
  transform: ImageTransform = {};
  rotation = 0;
  scale = 1;

  constructor(
    private spinner: NgxSpinnerService,
    public authService: AuthService,
    private renderer: Renderer2,
    private configService: ConfigService,
    public customizerService: CustomizerService,
    private modalService: NgbModal,
    public local: LocalStorageService
  ) {

    this.config = this.configService.templateConf;
    this.isOpen = !this.config.layout.customizer.hidden;

    if (this.config.layout.sidebar.size) {
      this.size = this.config.layout.sidebar.size;
    }
  }

  @Output() directionEvent = new EventEmitter<Object>();

  ngOnInit() {

  }

  openModalSubirImagen(content) {

    this.rotation = 0;
    this.scale = 1;

    this.toggleCustomizer()

    this.modalService.open(content, { windowClass:'editarAvatar', centered: true, backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      console.log(this.closeResult);
    }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        console.log(this.closeResult);
    });

  }

  cancelarModalSubirImagen() {

    this.imageChangedEvent = null;
    this.showCropper = false;

    this.modalService.dismissAll();

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
    } else {
        return `with: ${reason}`;
    }
  }

  changeSidebarWidth(value) {
    this.size = value;
    this.customizerService.changeSidebarWidth(value);
  }

  toggleCustomizer() {
    if (this.isOpen) {
      this.renderer.removeClass(this.customizer.nativeElement, 'open');
      this.isOpen = false;
    } else {
      this.renderer.addClass(this.customizer.nativeElement, 'open');
      this.isOpen = true;
    }
  }

  closeCustomizer() {
    this.renderer.removeClass(this.customizer.nativeElement, 'open');
    this.isOpen = false;
  }

  bgImageDisplay(e) {
    if (e.target.checked) {
      this.isBgImageDisplay = true;
    } else {
      this.isBgImageDisplay = false;
    }
    //emit event to FUll Layout
    this.customizerService.bgImageDisplay(e);
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  guardarCambios() {

    this.spinner.show();

    this.customizerService.guardaractualizarConfiguracionTemplate(this.authService.usuarioConectado.Configuracion).subscribe((datos) => {

      if (datos != null && datos.code == 200) {

        this.local.set('UsuarioCompleto', JSON.stringify(this.authService.usuarioConectado));

        const opciones: SweetAlertOptions =  {
          icon: 'success',
          title: 'Guardado Exitoso',
          text: 'Guardado exitoso',
          type: 'success',
          allowOutsideClick: false,
          allowEscapeKey: false
        } as SweetAlertOptions;

        swal.fire(opciones);

        this.spinner.hide();

      } else {

        const opciones: SweetAlertOptions =  {
          icon: 'warning',
          title: 'Error',
          text: datos.message,
          type: 'warning',
          allowOutsideClick: false,
          allowEscapeKey: false
        } as SweetAlertOptions;

        swal.fire(opciones);

        this.spinner.hide();

      }

    });

  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
    this.imageChangedEvent = e;
  }

  fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  fileChangeEvent(event: any): void {
    console.log('fileChangeEvent');
    this.fileName = event.target.files[0].name;
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady() {
      // cropper ready
  }

  loadImageFailed() {
      // show message
  }

  cancelCropping() {
    this.imageChangedEvent = null;
    this.showCropper = false;
  }

  guardarImagen() {

    this.spinner.show();

    let empresaconfiguracionImagenes = {

      IdEmpresa: this.authService.usuarioConectado.Empresa.IdEmpresa,
      IdUsuario: this.authService.usuarioConectado.Usuario.IdUsuario,
      Usuario: this.authService.usuarioConectado.Usuario.Nombre,
      Programa: 'Customizer',
      Activo: true,
      Active: true

    } as EmpresaConfiguracionImagenes;

    const documentoSubir = {
      nombreArchivo: 'imagenescustomizer/' + this.authService.usuarioConectado.Usuario.IdUsuario  + '/' + this.fileName,
      contenedor: this.authService.usuarioConectado.Empresa.Identificador.toLocaleLowerCase().trim(),
      esImagen: true,
      archivo: this.croppedImage.replace('data:image/png;base64,', '')
    } as Documento;

    empresaconfiguracionImagenes.documento = documentoSubir;

    this.configService.guardarImagen(empresaconfiguracionImagenes).subscribe((datos) => {

      if (datos != null && datos.code === 200) {

        empresaconfiguracionImagenes = datos.model;

        this.authService.usuarioConectado.ListaEmpresaConfiguracionImagenes.push(empresaconfiguracionImagenes);

        this.customizerService.changeSidebarBgImage(empresaconfiguracionImagenes);

        this.cancelarModalSubirImagen();

        this.spinner.hide();

      } else {

        const opciones: SweetAlertOptions = {
          icon: 'error',
          title: 'Error',
          text: datos.message,
          type: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false
        } as SweetAlertOptions;

        this.spinner.hide();

        swal.fire(opciones);

      }


    });

  }

  borrarImagen(imagen) {

    console.log('borrarImagen');
    console.log(imagen);

    const opciones: SweetAlertOptions = {
      icon: 'question',
      title: 'Eliminar imagen',
      text: '¿Estas seguro que deseas eliminar la imagen?',
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#30d657',
      cancelButtonColor: '#d64c30',
      allowOutsideClick: false,
      allowEscapeKey: false
    } as SweetAlertOptions;

    swal.fire(opciones).then((resultado) => {
      if (resultado.value) {
        // ir a la base a borrar la imagen y despues borrarla de la lista

        this.spinner.show();

        this.configService.eliminarImagen({IdImagen: imagen.IdImagen} as EmpresaConfiguracionImagenes).subscribe((datos) => { 

          console.log(datos);

          if (datos != null && datos.code == 200) {

            let indexImagen = this.authService.usuarioConectado.ListaEmpresaConfiguracionImagenes.findIndex(i => i.IdImagen == imagen.IdImagen);

            this.authService.usuarioConectado.ListaEmpresaConfiguracionImagenes = R.remove(indexImagen, 1, this.authService.usuarioConectado.ListaEmpresaConfiguracionImagenes);

            this.spinner.hide();

          } else {

            const opciones: SweetAlertOptions = {
              icon: 'error',
              title: 'Error',
              text: datos.message,
              type: 'error',
              allowOutsideClick: false,
              allowEscapeKey: false
            } as SweetAlertOptions;

            this.spinner.hide();

            swal.fire(opciones);

          }

        });
      }
    });    

  }

  getFile(event: any){
    console.log('file: ', event.target.files[0]);

    this.fileName = event.target.files[0].name;
    this.imageChangedEvent = event;
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
      this.canvasRotation++;
      this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
        ...this.transform,
        flipH: flippedV,
        flipV: flippedH
    };
  }

  flipHorizontal() {
    this.transform = {
        ...this.transform,
        flipH: !this.transform.flipH
    };
  }

  flipVertical() {
      this.transform = {
          ...this.transform,
          flipV: !this.transform.flipV
      };
  }

  updateRotation() {
    this.transform = {
        ...this.transform,
        rotate: this.rotation
    };
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
  }

  zoomIn() {
      this.scale += .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  revisarRotation(){
    console.log(this.rotation);

    if (this.rotation == undefined || this.rotation == null) {
      this.rotation = 0;
    }
  }

  zoomChange(){
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  revisarZoom(){
    console.log(this.scale);

    if (this.scale == undefined || this.scale == null) {
      this.scale = 1;
    }
  }

}
