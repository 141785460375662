import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import firebase from 'firebase/app'
import { Observable, Subject } from 'rxjs';
import { UsuarioCompleto } from 'app/oDres/modelos/Empresa/usuarioCompleto';
import { Chat } from 'app/oDres/modelos/Generales/chat';
import { TransaccionComercialCompleta } from 'app/oDres/modelos/Empresa/transaccionComercialCompleta';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexYAxis,
  ApexGrid,
  ApexDataLabels,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexLegend,
  ApexPlotOptions,
  ApexFill,
  ApexMarkers,
  ApexTheme,
  ApexNonAxisChartSeries,
  ApexResponsive,
  ChartComponent
} from "ng-apexcharts";
import { PagoClienteCompleto } from 'app/oDres/modelos/Cobranza/pagoClienteCompleto';
import { PagoProveedorCompleto } from 'app/oDres/modelos/PagoProveedor/pagoProveedorCompleto';
import { TransaccionNominaCompleta } from 'app/oDres/modelos/Nomina/transaccionNominaCompleto';
import { Calendario } from 'app/oDres/modelos/Nomina/calendario';

export type ChartOptions = {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  colors: string[],
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[],
  title: ApexTitleSubtitle;
  dataLabels: ApexDataLabels,
  stroke: ApexStroke,
  grid: ApexGrid,
  legend?: ApexLegend,
  tooltip?: ApexTooltip,
  plotOptions?: ApexPlotOptions,
  labels?: string[],
  fill: ApexFill,
  markers?: ApexMarkers,
  theme: ApexTheme,
  responsive: ApexResponsive[]
};

@Injectable()
export class AuthService {
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;

  usuarioConectado: UsuarioCompleto =  {} as UsuarioCompleto;
  pagoClienteCompleto = {} as PagoClienteCompleto;
  pagoProveedorCompleto = {} as PagoProveedorCompleto;
  chats: Chat[] = [];
  chatActivo: Chat = {};
  isChatOpen: boolean = false;
  listaUsuarioCompleto = [];
  IdEmpresaSeleccionada = 0;
  listaTransaccionComercialCompleta: TransaccionComercialCompleta[] = [];
  listaTransaccionNominaCompleta: TransaccionNominaCompleta[] = [];
  tipoGraficaVenta = 'PorAno';
  cerrarModalCobranza = new Subject<boolean>();
  cerrarModalCXP = new Subject<boolean>();
  cerrarModalTercero = new Subject<boolean>();
  cerrarModalProducto = new Subject<boolean>();
  cerrarModalBancos = new Subject<boolean>();
  cerrarModalVendedor = new Subject<boolean>();
  cerrarModalComprador = new Subject<boolean>();
  stringArrayTotales = new Subject<any>();
  stringArraySeries = new Subject<string[]>();
  stringArrayDate = new Subject<string[]>();

  enviarCerrarTimbrarNomina = false;
  calendarioSeleccionado: Calendario = {} as Calendario;
  listaCalendario = [];

  constructor(public router: Router) {
    // this.user = _firebaseAuth.authState;
    // this.user.subscribe(
    //   (user) => {
    //     if (user) {
    //       this.userDetails = user;
    //     }
    //     else {
    //       this.userDetails = null;
    //     }
    //   }
    // );

  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
    // return this._firebaseAuth.signInWithEmailAndPassword(email, password)

    //uncomment above firebase auth code and remove this temp code
    return new Promise(function(resolve, reject) {
      setTimeout(function() {
        resolve(true);
      }, 1000);
    });

  }

  logout() {
    //this._firebaseAuth.signOut();
    this.router.navigate(['YOUR_LOGOUT_URL']);
  }

  isAuthenticated() {
    return true;
  }
}
