import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { _ } from 'core-js';
import { environment } from 'environments/environment';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { CallService } from '../data/callService';
import { ConfiguracionTemplate } from 'app/oDres/modelos/Generales/configuracionTemplate';
import { JsonResponse } from 'app/oDres/modelos/Datos/jsonResponse';

@Injectable({
  providedIn: "root"
})
export class CustomizerService extends CallService {

  // sidebar BG colors for Light & Dark Layout
  light_dark_colors = [
    {
      code: "mint",
      class: "gradient-mint",
      active: false,
      type: 'gradient'
    },
    {
      code: "king-yna",
      class: "gradient-king-yna",
      active: false,
      type: 'gradient'
    },
    {
      code: "ibiza-sunset",
      class: "gradient-ibiza-sunset",
      active: false,
      type: 'gradient'
    },
    {
      code: "flickr",
      class: "gradient-flickr",
      active: false,
      type: 'gradient'
    },
    {
      code: "purple-bliss",
      class: "gradient-purple-bliss",
      active: false,
      type: 'gradient'
    },
    {
      code: "man-of-steel",
      class: "gradient-man-of-steel",
      active: false,
      type: 'gradient'
    },
    {
      code: "purple-love",
      class: "gradient-purple-love",
      active: false,
      type: 'gradient'
    },
    {
      code: "black",
      class: "bg-black",
      active: false,
      type: 'solid'
    },
    {
      code: "white",
      class: "bg-grey",
      active: false,
      type: 'solid'
    },
    {
      code: "primary",
      class: "bg-primary",
      active: false,
      type: 'solid'
    },
    {
      code: "success",
      class: "bg-success",
      active: false,
      type: 'solid'
    },
    {
      code: "warning",
      class: "bg-warning",
      active: false,
      type: 'solid'
    },
    {
      code: "info",
      class: "bg-info",
      active: false,
      type: 'solid'
    },
    {
      code: "danger",
      class: "bg-danger",
      active: false,
      type: 'solid'
    }
  ];

  // sidebar BG colors for Transparent Layout
  transparent_colors = [
    {
      class: "bg-glass-hibiscus",
      active: false
    },
    {
      class: "bg-glass-purple-pizzazz",
      active: false
    },
    {
      class: "bg-glass-blue-lagoon",
      active: false
    },
    {
      class: "bg-glass-electric-violet",
      active: false
    },
    {
      class: "bg-glass-portage",
      active: false
    },
    {
      class: "bg-glass-tundora",
      active: false
    }
  ];

  // sidebar BG images for Light & Dark Layout
  light_dark_bg_images = [
    {
      src: "https://grupoodresdiag.blob.core.windows.net/odes191231/010.jpg",
      active: false
    },
    {
      src: "https://grupoodresdiag.blob.core.windows.net/odes191231/020.jpg",
      active: false
    },
    {
      src: "https://grupoodresdiag.blob.core.windows.net/odes191231/030.jpg",
      active: false
    },
    {
      src: "https://grupoodresdiag.blob.core.windows.net/odes191231/040.jpg",
      active: false
    },
    {
      src: "https://grupoodresdiag.blob.core.windows.net/odes191231/050.jpg",
      active: false
    },
    {
      src: "https://grupoodresdiag.blob.core.windows.net/odes191231/060.jpg",
      active: false
    },
  ];

  // Background Colors with Shades for Transparent Layout
  transparent_colors_with_shade = [
    {
      class: "bg-glass-1",
      active: false
    },
    {
      class: "bg-glass-2",
      active: false
    },
    {
      class: "bg-glass-3",
      active: false
    },
    {
      class: "bg-glass-4",
      active: false
    },
  ];

  lightDarkLayoutGradientBGColors: any = [];
  lightDarkLayoutSolidBGColors: any = [];
  transparentLayoutBGColors: any = [];
  transparentLayoutBGColorsWithShades: any = [];
  lightDarkLayoutBGImages: any = [];

  private urls = {

    agregarConfiguracionTemplate: environment.urlApi + '/externo/api/configuracionTemplate/agregar-ConfiguracionTemplate',
    actualizarConfiguracionTemplate: environment.urlApi + '/externo/api/configuracionTemplate/actualizar-ConfiguracionTemplate',
    guardaractualizarConfiguracionTemplate: environment.urlApi + '/externo/api/configuracionTemplate/guardar-actualizar-ConfiguracionTemplate'
  }

  constructor(private config: ConfigService,
    private authService: AuthService,
    protected http: HttpClient) {
      super(http);
    this.getData();
  }

  getData() {

    //console.log('getData customizer.service');

    if (this.authService.usuarioConectado != null
      && this.authService.usuarioConectado.Usuario != null
      && this.authService.usuarioConectado.Usuario.IdUsuario != null
      && this.authService.usuarioConectado.ListaCatalogoConfiguracionImagenes != null
      && this.authService.usuarioConectado.ListaCatalogoConfiguracionImagenes != undefined) {
      this.authService.usuarioConectado.ListaCatalogoConfiguracionImagenes.forEach((imagen) => {
        imagen.Active = false;
      });

      const indexImagenCat = this.authService.usuarioConectado.ListaCatalogoConfiguracionImagenes
                          .findIndex(i => i.Ruta ==
                            this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundImageURL);

      if (indexImagenCat > -1) {
        this.authService.usuarioConectado.ListaCatalogoConfiguracionImagenes[indexImagenCat].Active = true;
      }

      if (this.authService.usuarioConectado.ListaEmpresaConfiguracionImagenes != null
        &&  this.authService.usuarioConectado.ListaEmpresaConfiguracionImagenes != undefined
        && this.authService.usuarioConectado.ListaEmpresaConfiguracionImagenes.length > 0) {

          this.authService.usuarioConectado.ListaEmpresaConfiguracionImagenes.forEach((imagen) => {
            imagen.Active = false;
          });

        if (indexImagenCat == -1) {

          const indexImagen = this.authService.usuarioConectado.ListaEmpresaConfiguracionImagenes
            .findIndex(i => i.Ruta ==
            this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundImageURL);

          if (indexImagen > -1) {
            this.authService.usuarioConectado.ListaEmpresaConfiguracionImagenes[indexImagen].Active = true;
          } else {
            this.authService.usuarioConectado.ListaCatalogoConfiguracionImagenes[0].Active = true;
          }

        }

      }
    }

    this.lightDarkLayoutGradientBGColors = this.getlightDarkLayoutGradientBGColors();
    this.lightDarkLayoutSolidBGColors = this.getlightDarkLayoutSolidBGColors();
    this.transparentLayoutBGColors = this.getTransparentLayoutBGColors();
    this.transparentLayoutBGColorsWithShades = this.GetTransparentLayoutBGColorsWithShades();
    this.lightDarkLayoutBGImages = this.getLightDarkLayoutBGImages();
  }

  getlightDarkLayoutGradientBGColors() {
    //console.log(this.config.templateConf);
    return this.light_dark_colors.filter(_ => _.type === 'gradient')
      .map(color => {
        color.active = (color.code === this.config.templateConf.layout.sidebar.backgroundColor);
        return { ...color };
      });
  }

  getlightDarkLayoutSolidBGColors() {
    return this.light_dark_colors.filter(_ => _.type === 'solid')
      .map(color => {
        color.active = (color.code === this.config.templateConf.layout.sidebar.backgroundColor);
        return { ...color };
      });
  }

  getTransparentLayoutBGColors() {
    return this.transparent_colors
      .map(color => {
        color.active = (color.class === this.config.templateConf.layout.sidebar.backgroundColor);
        return { ...color };
      });
  }

  GetTransparentLayoutBGColorsWithShades() {
    return this.transparent_colors_with_shade
      .map(color => {
        color.active = (color.class === this.config.templateConf.layout.sidebar.backgroundColor);
        return { ...color };
      });
  }

  getLightDarkLayoutBGImages() {
    return this.light_dark_bg_images
      .map(image => {
        image.active = (image.src === this.config.templateConf.layout.sidebar.backgroundImageURL);
        return { ...image };
      });
  }



  //called when click to change on any Gradient/Solid color for Light & Dark layout in customizer
  changeSidebarBGColor(color) {

    let conf = this.config.templateConf;
    conf.layout.sidebar.backgroundColor = color.code;    
    this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundColor = color.code;
    this.authService.usuarioConectado.Configuracion.backgroundColor = color.code;

    this.config.applyTemplateConfigChange({ layout: conf.layout });

    this.getData();

  }

  //called when click to change on any Transparent color for Transparent layout in customizer
  changeSidebarTransparentBGColor(color) {

    let conf = this.config.templateConf;
    conf.layout.sidebar.backgroundColor = color.class;
    conf.layout.sidebar.backgroundImage = false;
    conf.layout.sidebar.backgroundImageURL = '';

    this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundColor = color.class;
    this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundImage = false;
    this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundImageURL = '';

    this.authService.usuarioConectado.Configuracion.backgroundColor = color.class;
    this.authService.usuarioConectado.Configuracion.backgroundImage = false;
    this.authService.usuarioConectado.Configuracion.backgroundImageUrl = '';

    this.config.applyTemplateConfigChange({ layout: conf.layout });

    this.getData();
  }


  //called when click to change on any image for Light & Dark layout in customizer
  changeSidebarBgImage(image) {

    let conf = this.config.templateConf;
    conf.layout.sidebar.backgroundImageURL = image.Ruta;

    this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundImageURL = image.Ruta;
    this.authService.usuarioConectado.Configuracion.backgroundImageUrl = image.Ruta;

    this.config.applyTemplateConfigChange({ layout: conf.layout });

    this.getData();

  }

  bgImageDisplay(e: any) {
    let conf = this.config.templateConf;
    if (e.target.checked) {
      conf.layout.sidebar.backgroundImage = true;
      this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundImage = true;
    } else {
      conf.layout.sidebar.backgroundImage = false;
      this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundImage = false;
    }

    this.authService.usuarioConectado.Configuracion.backgroundImage = this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundImage;

    this.config.applyTemplateConfigChange({ layout: conf.layout });
  }

  toggleCompactMenu(e: any) {
    let conf = this.config.templateConf;
    if (e.target.checked) {
      conf.layout.sidebar.collapsed = true;
      this.authService.usuarioConectado.TemplateConf.layout.sidebar.collapsed = true;
    } else {
      conf.layout.sidebar.collapsed = false;
      this.authService.usuarioConectado.TemplateConf.layout.sidebar.collapsed = false;
    }

    this.authService.usuarioConectado.Configuracion.collapsed = this.authService.usuarioConectado.TemplateConf.layout.sidebar.collapsed;

    this.config.applyTemplateConfigChange({ layout: conf.layout });
  }


  changeSidebarWidth(value: string) {
    let conf = this.config.templateConf;
    conf.layout.sidebar.size = value;
    this.authService.usuarioConectado.TemplateConf.layout.sidebar.size = value;
    this.authService.usuarioConectado.Configuracion.size = this.authService.usuarioConectado.TemplateConf.layout.sidebar.size;

    this.config.applyTemplateConfigChange({ layout: conf.layout });
  }

  toggleNavbarType(value: string) {
    let conf = this.config.templateConf;
    conf.layout.navbar.type = value;
    this.authService.usuarioConectado.TemplateConf.layout.navbar.type = value;
    this.authService.usuarioConectado.Configuracion.type = this.authService.usuarioConectado.TemplateConf.layout.navbar.type;

    this.config.applyTemplateConfigChange({ layout: conf.layout });
  }

  // position: "Side" for vertical menu and position: "Top" for horizontal menu
  toggleMenuPosition(position: string) {
    //console.log('toggleMenuPosition');
    let conf = this.config.templateConf;
    conf.layout.menuPosition = position;
    this.authService.usuarioConectado.TemplateConf.layout.menuPosition = position;
    this.authService.usuarioConectado.Configuracion.menuPosition = position;
    this.config.applyTemplateConfigChange({ layout: conf.layout });
  }

  switchLayout(layout: string, isBgImageDisplay: boolean) {
    let conf = this.config.templateConf;
    if(layout.toLowerCase() === 'light') {
      conf.layout.variant = 'Light';
      conf.layout.sidebar.backgroundImageURL = this.light_dark_bg_images[0].src;
      conf.layout.sidebar.backgroundColor = this.light_dark_colors[5].code;
      conf.layout.sidebar.backgroundImage = isBgImageDisplay;

      this.authService.usuarioConectado.TemplateConf.layout.variant = 'Light';
      this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundImageURL = this.light_dark_bg_images[0].src;
      this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundColor = this.light_dark_colors[5].code;
      this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundImage = isBgImageDisplay;
    }
    else if(layout.toLowerCase() === 'dark') {
      conf.layout.variant = 'Dark';
      conf.layout.sidebar.backgroundImageURL = this.light_dark_bg_images[2].src;
      conf.layout.sidebar.backgroundColor = this.light_dark_colors[7].code;
      conf.layout.sidebar.backgroundImage = isBgImageDisplay;

      this.authService.usuarioConectado.TemplateConf.layout.variant = 'Dark';
      this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundImageURL = this.light_dark_bg_images[2].src;
      this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundColor = this.light_dark_colors[7].code;
      this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundImage = isBgImageDisplay;
    }
    else if(layout.toLowerCase() === 'transparent') {

      conf.layout.variant = 'Transparent';
      conf.layout.sidebar.backgroundImageURL = "";
      conf.layout.sidebar.backgroundColor = this.transparent_colors_with_shade[0].class;

      this.authService.usuarioConectado.TemplateConf.layout.variant = 'Transparent';
      this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundImageURL = '';
      this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundColor = this.transparent_colors_with_shade[0].class;
      this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundImage = false;
    }

    this.authService.usuarioConectado.Configuracion.variant = this.authService.usuarioConectado.TemplateConf.layout.variant;
    this.authService.usuarioConectado.Configuracion.backgroundImageUrl = this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundImageURL;
    this.authService.usuarioConectado.Configuracion.backgroundColor = this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundColor;
    this.authService.usuarioConectado.Configuracion.backgroundImage = this.authService.usuarioConectado.TemplateConf.layout.sidebar.backgroundImage;

    this.config.applyTemplateConfigChange({ layout: conf.layout });

    this.getData();
  }

  actualizarConfiguracionTemplate(configuracionTemplate: ConfiguracionTemplate) {

    return this.post<JsonResponse>(this.urls.actualizarConfiguracionTemplate, configuracionTemplate);
  }

  agregarConfiguracionTemplate(configuracionTemplate: ConfiguracionTemplate) {

    return this.post<JsonResponse>(this.urls.agregarConfiguracionTemplate, configuracionTemplate);
  }

  guardaractualizarConfiguracionTemplate(configuracionTemplate: ConfiguracionTemplate) {

    return this.post<JsonResponse>(this.urls.guardaractualizarConfiguracionTemplate, configuracionTemplate);
  }

}
