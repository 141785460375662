import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as SignalR from '@aspnet/signalr';
import { MensajesChat } from 'app/oDres/modelos/Generales/mensajesChat';
import { MensajeSignalR } from 'app/oDres/modelos/SignalR/mensajeSignalR ';
import { SignalRConnection } from 'app/oDres/modelos/SignalR/signalRConnection';
import { isThisSecond } from 'date-fns';
import { environment } from 'environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthService, ChartOptions } from '../auth/auth.service';
import { CallService } from '../data/callService';
import * as moment from 'moment';
import { GeneralesService } from 'app/oDres/modulos/generales/datos/generales.service';
import { TransaccionComercialDetalle } from 'app/oDres/modelos/Empresa/transaccionComercialDetalle';
import { Router } from '@angular/router';
import { ChartComponent } from 'ng-apexcharts';
import * as R from 'ramda';
import { DashboardPerfilAdministradorVentasComponent } from 'app/oDres/modulos/dashboards/paginas/dashboard-perfil-administrador-ventas/dashboard-perfil-administrador-ventas.component';
var $primary = "#975AFF",
  $success = "#40C057",
  $info = "#2F8BE6",
  $warning = "#F77E17",
  $danger = "#F55252",
  $label_color_light = "#E6EAEE";
var themeColors = [$primary, $warning, $success, $danger, $info];

@Injectable({
  providedIn: 'root'
})
export class SignalRService extends CallService {

  currentRoute: string;
  stringArray = [];
  stringArrayDate = [];


  //#region SignalR
  mensajeSignalR: MensajeSignalR;
  private hubConnection: SignalR.HubConnection;
  //#endregion  

  constructor(public authService: AuthService,
    private router: Router,
    public toastr: ToastrService,
    protected http: HttpClient,
    private spinner: NgxSpinnerService,
    public generalesService: GeneralesService) {
      super(http);

    this.inicializarSignalR();

  }

  inicializarSignalR() {

    this.getSignalRConnection().subscribe(con => {
      const options = {
        accessTokenFactory: () => con.accessToken
      };

      this.hubConnection = new SignalR.HubConnectionBuilder()
        .withUrl(con.url, options)
        .configureLogging(SignalR.LogLevel.Information)
        .build();

      this.hubConnection.on('mensajesSignalR', data => {
        //console.log(data);

        this.mensajeSignalR = data;

        // notificaciones de campanita
        if (this.mensajeSignalR.notificacion != null
          && this.mensajeSignalR.notificacion.IdNotificacion != null
          && ((this.mensajeSignalR.notificacion.IdUsuarioDirigidoA != null && (this.mensajeSignalR.notificacion.IdUsuarioDirigidoA.toLocaleLowerCase() == this.authService.usuarioConectado.Usuario.IdUsuario.toLocaleLowerCase())) ||
            this.mensajeSignalR.notificacion.EsDesdeSistema)) {

          this.authService.usuarioConectado.ListaNotificaciones.push(this.mensajeSignalR.notificacion);

          const numeroAbiertas = this.authService.usuarioConectado.ListaNotificaciones.filter(n => n.Abierta == true).length;
          this.authService.usuarioConectado.NumeroNotificacionesSinVer = numeroAbiertas;

          switch (this.mensajeSignalR.tipoToastr) {
            case 'success':
              this.toastr.success(this.mensajeSignalR.titulo, this.mensajeSignalR.mensaje, { closeButton: true, positionClass: 'toast-top-center' });
              break;
            case 'info':
              this.toastr.info(this.mensajeSignalR.titulo, this.mensajeSignalR.mensaje, { closeButton: true, positionClass: 'toast-top-center' });
              break;
            case 'warning':
              this.toastr.warning(this.mensajeSignalR.titulo, this.mensajeSignalR.mensaje, { closeButton: true, positionClass: 'toast-top-center' });
              break;
            case 'error':
              this.toastr.error(this.mensajeSignalR.titulo, this.mensajeSignalR.mensaje, { closeButton: true, positionClass: 'toast-top-center' });
              break;
          }
        }

        // Para tareas
        if (this.mensajeSignalR.tarea != null && this.mensajeSignalR.tarea.IdTarea != null
          && (this.mensajeSignalR.tarea.IdUsuarioDirigidoA.toLocaleLowerCase() == this.authService.usuarioConectado.Usuario.IdUsuario.toLocaleLowerCase())) {

          this.authService.usuarioConectado.tareasXUsuario.TareasNuevas.push(this.mensajeSignalR.tarea);
          this.authService.usuarioConectado.tareasXUsuario.TareasNuevasTotal = this.authService.usuarioConectado.tareasXUsuario.TareasNuevas.length;

          this.spinner.show();
          this.spinner.hide();

        }

        // Para tablero totales ventas

        if (this.mensajeSignalR.listaTotalesVentasTableroXAno != null && this.mensajeSignalR.listaTotalesVentasTableroXAno.length > 0
          && this.router.url == '/dashboards/dashboard-administrador-ventas') {

          this.stringArray = [];
          this.stringArrayDate = [];

          if (this.authService.tipoGraficaVenta == 'PorAno') {
            this.mensajeSignalR.listaTotalesVentasTableroXAno.forEach(element => {
              this.stringArray.push(element.TotalDD.toString());
              this.stringArrayDate.push(element.YY + '-' + element.MM.toString().padStart(2, '0') + '-01T00:00:00.000Z');
            });
          } else {
            this.mensajeSignalR.listaTotalesVentasTableroXAnoMes.forEach(element => {
              this.stringArray.push(element.TotalDD.toString());
              this.stringArrayDate.push(element.YY + '-' + element.MM.toString().padStart(2, '0') + '-' + element.DD.toString().padStart(2, '0') + 'T00:00:00.000Z');
            });
          }

          this.authService.stringArrayTotales.next(this.mensajeSignalR.totalesVentasTablero);
          this.authService.stringArraySeries.next(this.stringArray);
          this.authService.stringArrayDate.next(this.stringArrayDate);

          // this.dashboardVentas.chart.updateOptions({
          //   chart: {
          //     height: 350,
          //     type: 'area',
          //   },
          //   colors: themeColors,
          //   dataLabels: {
          //     enabled: false
          //   },
          //   stroke: {
          //     curve: 'smooth'
          //   },
          //   series: [{
          //     name: 'Ventas',
          //     data: this.stringArray
          //   }],
          //   legend: {
          //     offsetY: -10
          //   },
          //   xaxis: {
          //     type: 'datetime',
          //     categories: this.stringArrayDate
          //   },
          //   tooltip: {
          //     x: {
          //       format: 'MM/yy'
          //     },
          //   }
          // });
        }

        // para chat
        if (this.mensajeSignalR.chats != null && this.mensajeSignalR.chats.IdChat != null) {

          // si encuentra al usuario en la lista de usuarios significa que es un chat del usuario conectado      
          if (this.mensajeSignalR.chats.listaUsuariosChat.findIndex(u => u.IdUsuario.toLocaleLowerCase() == this.authService.usuarioConectado.Usuario.IdUsuario.toLocaleLowerCase()) > -1) {

            // Checamos primero por IdChat
            let indexChat = this.authService.chats.findIndex(u => u.IdChat.toLocaleLowerCase() == this.mensajeSignalR.chats.IdChat.toLocaleLowerCase());

            if (indexChat > -1) {

              this.authService.chats[indexChat].listaUsuariosChat = this.mensajeSignalR.chats.listaUsuariosChat;

              this.authService.chats[indexChat].lastChatTime = this.mensajeSignalR.chats.ultimaHora;
              this.authService.chats[indexChat].lastChatMessage = this.mensajeSignalR.chats.ultimoMensaje;

              if (!this.authService.chats[indexChat].isActiveChat) {
                this.authService.chats[indexChat].unreadMessageCount = this.authService.chats[indexChat].unreadMessageCount == null ? '1' : ((+this.authService.chats[indexChat].unreadMessageCount) + 1).toString();
              }

              this.spinner.show();
              this.spinner.hide();

            } else {

              // Aqui hay que ir por los chats de esa persona que esta conectada porque posiblemente aun no los agrega en su lista de chats

              this.generalesService.obtenerChatsUsuario(this.authService.usuarioConectado.Empresa.IdEmpresa, this.authService.usuarioConectado.Usuario.IdUsuario).subscribe(respuestaChats => {

                //console.log(respuestaChats);

                if (respuestaChats != null && respuestaChats.code == 200) {

                  this.authService.chats = respuestaChats.model;

                  let indexChat = this.authService.chats.findIndex(u => u.IdChat.toLocaleLowerCase() == this.mensajeSignalR.chats.IdChat.toLocaleLowerCase());

                  if (indexChat > -1) {

                    this.authService.chats[indexChat].listaUsuariosChat = this.mensajeSignalR.chats.listaUsuariosChat;

                    this.authService.chats[indexChat].lastChatTime = this.mensajeSignalR.chats.ultimaHora;
                    this.authService.chats[indexChat].lastChatMessage = this.mensajeSignalR.chats.ultimoMensaje;

                    if (!this.authService.chats[indexChat].isActiveChat) {
                      this.authService.chats[indexChat].unreadMessageCount = this.authService.chats[indexChat].unreadMessageCount == null ? '1' : ((+this.authService.chats[indexChat].unreadMessageCount) + 1).toString();
                    }

                    this.spinner.show();
                    this.spinner.hide();

                  }

                }

              });

              // let indexChat = null;

              // this.authService.chats.forEach((chat, i) => {

              //   let indexUsuarioEnvioMensaje = chat.listaUsuariosChat.findIndex(u => u.IdUsuario.toLocaleLowerCase() == this.mensajeSignalR.chats.IdUsuario.toLocaleLowerCase());
              //   let indexUsuarioConecato = chat.listaUsuariosChat.findIndex(u => u.IdUsuario.toLocaleLowerCase() == this.authService.usuarioConectado.Usuario.IdUsuario.toLocaleLowerCase());

              //   if (indexUsuarioEnvioMensaje > -1 && indexUsuarioConecato > -1){
              //     indexChat = i;
              //     return;
              //   }

              // });

              // this.authService.chats[indexChat].mensajes = JSON.parse(this.mensajeSignalR.chats.JsonMensajes);
              // this.authService.chats[indexChat].lastChatTime = this.mensajeSignalR.chats.ultimaHora;
              // this.authService.chats[indexChat].lastChatMessage = this.mensajeSignalR.chats.ultimoMensaje;

              // if (!this.authService.chats[indexChat].isActiveChat){
              //   this.authService.chats[indexChat].unreadMessageCount = this.authService.chats[indexChat].unreadMessageCount == null ? '1' : ((+this.authService.chats[indexChat].unreadMessageCount)+1).toString();
              // }

            }

            // let indesUsuario = this.authService.chats.findIndex(u => u.IdUsuarioDestino == this.mensajeSignalR.chats.IdUsuario);

            // this.authService.chats[indesUsuario].mensajes = JSON.parse(this.mensajeSignalR.chats.JsonMensajes);

            // this.authService.chats[indesUsuario].lastChatTime = this.mensajeSignalR.chats.ultimaHora;
            // this.authService.chats[indesUsuario].lastChatMessage = this.mensajeSignalR.chats.ultimoMensaje;

            // if (!this.authService.chats[indesUsuario].isActiveChat){
            //   this.authService.chats[indesUsuario].unreadMessageCount = this.authService.chats[indesUsuario].unreadMessageCount == null ? '1' : ((+this.authService.chats[indesUsuario].unreadMessageCount)+1).toString();
            // }

            this.spinner.show();
            this.spinner.hide();

            if (!this.authService.isChatOpen && this.mensajeSignalR.chats.Usuario != null) {
              this.toastr.success('Mensaje Recibido', 'Nuevo Mensaje de ' + this.mensajeSignalR.chats.Usuario, {
                positionClass: 'toast-bottom-right',
                closeButton: true
              });
            }
          }

        }

        //Para TransaccionComercial
        if (this.mensajeSignalR.listaTransaccionComercialCompleta != null && this.mensajeSignalR.listaTransaccionComercialCompleta.length > 0
          && (this.router.url == '/compras-egresos/busquedaDocumentosCompras' || this.router.url == '/ventas-ingresos/busquedaDocumentosVentas')) {

          if (this.authService.listaTransaccionComercialCompleta != null && this.authService.listaTransaccionComercialCompleta.length > 0) {
            this.mensajeSignalR.listaTransaccionComercialCompleta.forEach(transaccionComercialCompleta => {

              transaccionComercialCompleta.transaccionComercial.esVisible = true;

              let indexTransaccion = this.authService.listaTransaccionComercialCompleta.findIndex(r => r.transaccionComercial.IdTransaccionComercial == transaccionComercialCompleta.transaccionComercial.IdTransaccionComercial);

              if (transaccionComercialCompleta.transaccionComercial.Bloqueado != null
                && (transaccionComercialCompleta.transaccionComercial.Bloqueado
                  || (!transaccionComercialCompleta.transaccionComercial.Bloqueado
                    && transaccionComercialCompleta.listaTransaccionComercialDetalle == null))) {

                this.authService.listaTransaccionComercialCompleta[indexTransaccion].transaccionComercial.Bloqueado = transaccionComercialCompleta.transaccionComercial.Bloqueado;
                this.authService.listaTransaccionComercialCompleta[indexTransaccion].transaccionComercial.UsuarioBloqueo = transaccionComercialCompleta.transaccionComercial.UsuarioBloqueo;
                this.authService.listaTransaccionComercialCompleta[indexTransaccion].transaccionComercial.NombreUsuarioBloqueo = transaccionComercialCompleta.transaccionComercial.NombreUsuarioBloqueo;

              } else if (transaccionComercialCompleta.transaccionComercial.Bloqueado != null
                && !transaccionComercialCompleta.transaccionComercial.Bloqueado
                && transaccionComercialCompleta.listaTransaccionComercialDetalle != null) {

                this.authService.listaTransaccionComercialCompleta[indexTransaccion] = transaccionComercialCompleta;

                this.authService.listaTransaccionComercialCompleta[indexTransaccion].transaccionComercial.verDetalle = false;
                this.authService.listaTransaccionComercialCompleta[indexTransaccion].transaccionComercial.esVisible = true;
                this.authService.listaTransaccionComercialCompleta[indexTransaccion].transaccionComercial.seleccionarCotizar = false;
                this.authService.listaTransaccionComercialCompleta[indexTransaccion].transaccionComercial.seleccionarTimbrar = false;

                this.authService.listaTransaccionComercialCompleta[indexTransaccion].listaTransaccionComercialDetalle.forEach((element: TransaccionComercialDetalle) => {
                  element.AbrirImpuesto = false;
                  element.AbrirRetencion = false;
                });
              }

            });

            this.spinner.show();
            this.spinner.hide();
          }

        }

        //Para TransaccionNomina
        if (this.mensajeSignalR.listaTransaccionNominaCompleta != null 
          && this.mensajeSignalR.listaTransaccionNominaCompleta.length > 0
          && this.router.url == '/rh-nomina/nominas-generadas') {

          if (this.authService.listaTransaccionNominaCompleta != null 
            && this.authService.listaTransaccionNominaCompleta.length > 0) {
            this.mensajeSignalR.listaTransaccionNominaCompleta.forEach(transaccionNominaCompleta => {

              transaccionNominaCompleta.esVisible = true;

              let indexTransaccion = this.authService.listaTransaccionNominaCompleta.findIndex(r => r.transaccionNomina.IdTransaccionNomina == transaccionNominaCompleta.transaccionNomina.IdTransaccionNomina);

              if (transaccionNominaCompleta.transaccionNomina.Bloqueado != null
                && (transaccionNominaCompleta.transaccionNomina.Bloqueado
                  || (!transaccionNominaCompleta.transaccionNomina.Bloqueado
                    && transaccionNominaCompleta.listaTransaccionDetalleNomina == null))) {

                this.authService.listaTransaccionNominaCompleta[indexTransaccion].transaccionNomina.Bloqueado = transaccionNominaCompleta.transaccionNomina.Bloqueado;
                this.authService.listaTransaccionNominaCompleta[indexTransaccion].transaccionNomina.UsuarioBloqueo = transaccionNominaCompleta.transaccionNomina.UsuarioBloqueo;
                this.authService.listaTransaccionNominaCompleta[indexTransaccion].transaccionNomina.NombreUsuarioBloqueo = transaccionNominaCompleta.transaccionNomina.NombreUsuarioBloqueo;

              } else if (transaccionNominaCompleta.transaccionNomina.Bloqueado != null
                && !transaccionNominaCompleta.transaccionNomina.Bloqueado
                && transaccionNominaCompleta.listaTransaccionDetalleNomina != null) {

                this.authService.listaTransaccionNominaCompleta[indexTransaccion] = transaccionNominaCompleta;

                this.authService.listaTransaccionNominaCompleta[indexTransaccion].verDetalle = false;
                this.authService.listaTransaccionNominaCompleta[indexTransaccion].esVisible = true;

              }

            });

            this.spinner.show();
            this.spinner.hide();
          }

        }

        // Aqui si llega la notificacion de que ya se cerro la nomina
        if (this.mensajeSignalR.enviarCerrarTimbrarNomina == false && this.router.url == '/rh-nomina/nominas-generadas') {
          this.authService.enviarCerrarTimbrarNomina = false;

          let indexCalendario = this.authService.listaCalendario.findIndex(c => c.IdCalendario == this.authService.calendarioSeleccionado.IdCalendario);

          this.authService.listaCalendario[indexCalendario].Cerrada = true;

          this.authService.calendarioSeleccionado = R.clone(this.authService.listaCalendario[indexCalendario]);

          this.spinner.show();
          this.spinner.hide();
        }
      });

      this.hubConnection.start().then(() => console.log('Connected!')).catch(error => console.error(error));

      this.hubConnection.serverTimeoutInMilliseconds = 300000;

      this.hubConnection.onclose((error) => {
        this.hubConnection.start();
        console.error(`Something went wrong: ${error}`);
      });
    });
  }

  private getSignalRConnection(): Observable<SignalRConnection> {
    return this.http.get<SignalRConnection>(`${environment.urlsignalR}negotiate`);
  }

}
