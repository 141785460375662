export const environment = {
  production: false,  
  urlApi: 'https://odresenterpriseapiprod-beanbvfudmfchaag.eastus2-01.azurewebsites.net',
  tokenCopomex: 'ae07fec1-522a-4ac9-b8af-cb7fdc6191dc',  
  urlServicioDireccion: 'https://appmovil.invextarjetas.com.mx/solicitud-digital/api/catalogs/getAddressByPostalCode/',
  urlsignalR: 'https://funcion-signalr-odres.azurewebsites.net/api/',
  urlApiReportes: 'https://odresreportingserviceqa-gabecqe2fsfgb9dh.eastus2-01.azurewebsites.net/',
  invokeActionReportViewer : "DXXRDV",
  invokeActionReportDesigner : "/DXXRD/GetDesignerModel", 
};

