

<ng-template #modificarImagen let-c="close" let-d="dismiss">
  <div class="modal-header" style="margin-top: -10px; padding: 1rem 1rem 0.5rem 1rem;">
    <h4 class="modal-title">Modifica imagen del grupo</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div style="text-align: center;" *ngIf="!imageChangedEvent">
      <label class="font-medium-2 text-uppercase">Arrasta la imagen aqui</label>
    </div>

    <div class="file-container" *ngIf="!imageChangedEvent" >
        <input type="file" class="file" (change)="getFile($event)" style="width:100%"/>              
    </div>
    
    <br *ngIf="!imageChangedEvent">

    <div style="text-align: center;" *ngIf="!imageChangedEvent">
      <label class="font-medium-2 text-uppercase">o Selecciona tu archivo</label>
    </div>

    <div class="row" *ngIf="!imageChangedEvent">                             
      <div class="custom-file mx-auto d-block col-md-8">
        <input type="file" class="custom-file-input" id="inputGroupFile01" (change)="fileChangeEvent($event)" accept="image/png, image/gif, image/jpeg">
        <label class="custom-file-label" for="inputGroupFile01">Seleccion tu archivo</label>
      </div>
    </div>

    <div class="row" *ngIf="imageChangedEvent" style="text-align: center;">
      <div class="col-md-12" style="margin-top: 10px;">
        <button type="button" class="btn btn-primary btn-raised" (click)="rotateLeft()" style="margin-left: 5px; width: 160px;">Gira a la izquierda</button>
        <button type="button" class="btn btn-primary btn-raised" (click)="rotateRight()" style="margin-left: 5px; width: 160px;">Gira a la derecha</button>
        <button type="button" class="btn btn-primary btn-raised" (click)="flipHorizontal()" style="margin-left: 5px; width: 160px;">Gira en horizontal</button>
        <button type="button" class="btn btn-primary btn-raised" (click)="flipVertical()" style="margin-left: 5px; width: 160px;">Gira en vertical</button>
        <button type="button" class="btn btn-warning btn-raised" (click)="cancelCropping()" style="margin-left: 5px; width: 180px;">Cancelar Imagen 
          <i class="ft-x position-right"></i>
        </button>    
      </div>
    </div>

    <div class="row" *ngIf="imageChangedEvent" style="text-align: center;">
      <div class="col-md-6">
        <div class="form-group">
          <table>
            <tr>
              <td>
                <label class="font-medium-2 text-uppercase">Rotar</label>
              </td>
              <td>
                <input type="number" class="form-control" min="0" max="360" maxlength="3" style="margin-left: 5px; width: 70px"
                    [(ngModel)]="rotation" [ngModelOptions]="{standalone: true}" (ngModelChange)="revisarRotation()">
              </td>
              <td>
                <nouislider [min]="0" [max]="360" [step]="1" [(ngModel)]="rotation" (ngModelChange)="updateRotation()" style="width: 300px; margin-top: 50px; margin-left: 5px;">
                </nouislider>
              </td>              
            </tr>
          </table>          
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <table>
            <tr>
              <td>
                <label class="font-medium-2 text-uppercase">Zoom</label>
              </td>
              <td>
                <input type="number" class="form-control" min="-10" max="10" maxlength="2" style="margin-left: 5px; width: 70px"
                    [(ngModel)]="scale" [ngModelOptions]="{standalone: true}" (ngModelChange)="revisarZoom()">
              </td>           
              <td>
                <nouislider [min]="-10" [max]="10" [step]="0.1" [(ngModel)]="scale" (ngModelChange)="zoomChange()" style="width: 300px; margin-top: 50px; margin-left: 5px;">
                </nouislider>
              </td>              
            </tr>
          </table>          
        </div>
      </div>
    </div>

    <div class="row">                               
      <div class="col-md-6" style="margin-top: 10px; text-align: center;">
        <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [containWithinAspectRatio]="false"
            [resizeToWidth]="256"
            [cropperMinWidth]="128"
            [onlyScaleDown]="true"
            [roundCropper]="true"
            [canvasRotation]="canvasRotation"
            [transform]="transform"
            [alignImage]="'left'"
            [style.display]="showCropper ? null : 'none'"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"            
        >
        </image-cropper>
      </div>
      <br>
      <div class="col-md-6" style="margin-top: 10px; text-align: center;" *ngIf="showCropper">
        <span class="result">
          <img [src]="croppedImage" style="width: 360px; border-radius: 100%;"/>           
        </span>
      </div>
      
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-raised" (click)="guardarImagen()">Guardar</button>
    <button type="button" class="btn btn-warning btn-raised" (click)="cancelarGuardarFoto()">Close</button>
  </div>
</ng-template>

<ng-template #crearGrupoChat let-c="close" let-d="dismiss">
  <div class="modal-header" style="margin-top: -10px">
    <h4 class="modal-title">Crear Nuevo Grupo</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-actions">
      <div class="row">
        <div class="col-md-5">
          <div class="form-group">
            <label for="projectinput1">Nombre *</label>
            <input type="text" id="projectinput1" class="form-control"
                name="titulogrupo" placeholder="Nombre Grupo"
                (keydown.enter)="$event.preventDefault()"
                [(ngModel)]="nuevoChatGrupal.NombreGrupo"
                style="width: 250px"
                maxlength = "20">            
          </div>
        </div> 
        
        <div class="col-md-7">
          <div class="d-flex col-md-10 mx-auto">
            <img
            [src]="croppedImage != '' ? croppedImage : nuevoChatGrupal.UrlImagen"  class="avatar mr-2" alt="Avatar" width="70" height="70">
            <div class="align-self-center">              
              <button type="button" class="btn btn-sm btn-primary" (click)="editarImagenGrupo(modificarImagen)">Cambiar imagen</button>
            </div>
          </div>
        </div> 
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
              <label for="projectinput1">Descripción *</label>
              <textarea class="form-control" [(ngModel)]="nuevoChatGrupal.DescripcionGrupo" name="DescripcionGrupo"
                  maxlength = "500"
                  placeholder="Descripcion del grupo" (keydown.enter)="$event.preventDefault()">
              </textarea>              
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-2"></div>        
        <div class="col-md-8">
          <div class="form-group">

            <table style="margin-top: 10px;">
              <tr>
                <td>
                  <label for="projectinput1" style="margin-right: 50px;">Integrantes: </label>                     
                </td>                
                <td>
                  <label for="ngx-filter-ref">Buscar Empleado:
                    <input class="form-control form-control-sm d-inline-block" style="width: 250px"
                        type="text" placeholder="Por Nombre" (keyup)="NombreEmpleadoFiltro($event)"/>
                  </label>
                </td>  
              </tr>
            </table>
            
            <table class="table table-responsive-md text-center">
              <thead>
                <tr>                
                  <th >Avatar</th>   
                  <th >Nombre Empleado</th>          
                  <th >Agregar</th>
                  <th >Administrador</th>
                </tr>
              </thead>
              <tbody >
                <tr *ngFor="let usuario of listaUsuariosChat; let i=index">
                  <td>
                    <img [src]="usuario.Urlimagen" class="avatar mr-2" alt="Avatar" width="40" height="40">                     
                  </td> 
                  <td> 
                    <div style="text-align: left; margin-left: 15px;">
                      <label style="font-size: 10pt;">{{usuario.NombreCompleto}}</label>
                    </div>                      
                  </td>                   
                  <td>
                    <ui-switch class="ml-1" size="small" (change)="seleccionoUsuario($event, usuario)" 
                      [checked]="usuario.seleccionado == true"></ui-switch> 
                  </td>
                  <td>
                    <ui-switch class="ml-1" size="small" (change)="seleccionoUsuarioAdministrador($event, usuario)" 
                      [checked]="usuario.administradorChat == true"
                      [disabled]="!usuario.seleccionado"></ui-switch> 
                  </td>      
                </tr>   
                <tr *ngIf="listaUsuariosChat.length == 0" role='row'>
                  <td colspan="3" class="paddingarriaba80 text-center">Sin registros que mostrar</td>
                </tr>      
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div style="height: 150px;"></div>

    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary btn-save" (click)="crearNuevoGrupo()">
      Aceptar
    </button>
    <button type="button" class="btn btn-secondary btn-raised" (click)="cerrarNuevoGrupo()">Close</button>
  </div>
</ng-template>

<ng-template #agregarEmoticon let-c="close" let-d="dismiss">
  <div class="modal-header" style="margin-top: -10px">
    <h4 class="modal-title">Emoticons</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-actions">

      <emoji-mart class="emoji-mart" set="{{set}}" (emojiSelect)="addEmoji($event)" title="Selecciona" ></emoji-mart>          

    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary btn-save" (click)="cerrarModalEmoticons()">
      Aceptar
    </button>
    <button type="button" class="btn btn-secondary btn-raised" (click)="cerrarModalEmoticons()">Close</button>
  </div>
</ng-template>

<ng-template #usuariosBuscar let-c="close" let-d="dismiss">
  <div class="modal-header" style="margin-top: -10px">
    <h4 class="modal-title">Usuarios</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-actions" style="margin-top: -10px">     
      <div class="row">              
        <div class="col-md-12">
          <div class="form-group">

            <label for="ngx-filter-ref" style="margin-bottom: 5px">Buscar Empleado:
              <input class="form-control form-control-sm d-inline-block" style="width: 250px"
                  type="text" placeholder="Por Nombre" (keyup)="NombreEmpleadoFiltro($event)"/>
            </label>

            <table class="table table-responsive-md text-center" *ngIf="listaUsuariosAgrupados.length == 1">
              <thead>
                <tr>                
                  <th >Avatar</th>   
                  <th >Nombre Empleado</th>
                </tr>
              </thead>
              <tbody >                
                <div *ngFor="let usuario of listaUsuariosAgrupados[0].Usuarios; let i=index" (click)="seleccionarUsuarioChat(usuario)">
                  <tr>
                    <td>
                      <img [src]="usuario.Urlimagen" class="avatar mr-2" alt="Avatar" width="40" height="40">                     
                    </td> 
                    <td> 
                      <div style="text-align: left; margin-left: 15px;">
                        <label style="font-size: 10pt;">{{usuario.NombreCompleto}}</label>
                      </div>                      
                    </td>
                  </tr>
                </div>                                       
              </tbody>
            </table>            

            <table class="table table-responsive-md text-center" *ngIf="listaUsuariosAgrupados.length > 1">              
              <tbody >
                <tr *ngIf="listaUsuariosAgrupados.length == 0" role='row'>
                  <td colspan="1" class="paddingarriaba80 text-center">Sin registros que mostrar</td>
                </tr>
                <ng-container *ngFor="let agrupado of listaUsuariosAgrupados; let i=index">
                  <tr *ngIf="agrupado.esVisible" (click)="verDetalle(i)">                 
                    <td> 
                      <div style="text-align: left; margin-left: 15px;">
                        <label style="font-size: 10pt;">{{agrupado.Letra}}</label>
                      </div>                                             
                    </td>
                  </tr>                     
                  <ng-container *ngIf="agrupado.verDetalle">
                    <tr>                 
                      <td> 
                        <table class="table table-responsive-md text-center">
                          <thead>
                            <tr>                
                              <th >Avatar</th>   
                              <th >Nombre Empleado</th>
                            </tr>
                          </thead>
                          <tbody >
                            <tr *ngFor="let usuario of agrupado.Usuarios; let i=index">
                              <td>
                                <img [src]="usuario.Urlimagen" class="avatar mr-2" alt="Avatar" width="40" height="40">                     
                              </td> 
                              <td> 
                                <div style="text-align: left; margin-left: 15px;">
                                  <label style="font-size: 10pt;">{{usuario.NombreCompleto}}</label>
                                </div>                      
                              </td>
                            </tr>                              
                          </tbody>
                        </table>                       
                      </td>
                    </tr>   
                  </ng-container>
                </ng-container>                                      
              </tbody>
            </table>
            
            
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div style="height: 150px;"></div>

    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary btn-save" (click)="crearNuevoGrupo()">
      Aceptar
    </button>
    <button type="button" class="btn btn-secondary btn-raised" (click)="cerrarModalUsuarios()">Close</button>
  </div>
</ng-template>

<div #chatEmergente class="chatEmergente d-none d-lg-none d-xl-block" [ngClass]="{open: authService.isChatOpen}">
    <a class="chatEmergente-close" (click)="closeCustomizer()">
        <i class="ft-x font-medium-3"></i>
    </a>
    <a class="chatEmergente-toggle bg-primary" id="chatEmergente-toggle-icon"
        (click)="toggleCustomizer()" 
        *ngIf="authService.usuarioConectado.TipoLicencia.NomenclaturaLicencia == 'PRE' || authService.usuarioConectado.TipoLicencia.EsIlimitada">
        <i class="fas fa-comment-alt font-medium-1 white align-middle"></i>
    </a>
    <div class="chatEmergente-content p-3 ps-container ps-theme-dark">     
      <h4 class="text-uppercase">Bienvenidos al chat</h4>
      <p>Busca un usuario o presiona en algun chat para seguir la conversación</p> 
    <div class="chat-application overflow-hidden">
        <div class="chat-sidebar d-md-block">
            <div class="chat-fixed-search py-2 px-3 my-1" style="margin-bottom: 4px !important;">
            <table>
                <tr>
                <td>
                    <form action="javascript:;" style="margin-right: 8px; margin-left: -15px;">
                    <div class="position-relative has-icon-left">
                        <input class="form-control" placeholder="Buscar usuarios" 
                        id="timesheetinput1" name="employeename" type="text" style="width: 225px;"
                        [(ngModel)]="searchQuery" (keyup)="NombreUsuarioFiltro($event)">               
                        <div class="form-control-position">
                        <i class="ft-user"></i>
                        </div>
                    </div>            
                    </form>
                </td>
                <td>
                    <i class="fas fa-search cursor-pointer" (click)="abrirModalUsuarios(usuariosBuscar)" style="margin-right: 8px;"></i>
                </td>
                <td>
                    <i class="fas fa-users cursor-pointer" (click)="abrirModalCrearGrupo(crearGrupoChat)"></i>
                </td>
                </tr>
            </table>
            </div>              
            <hr class="m-0">
            <div [perfectScrollbar] #chatSidebar id="users-list" class="list-group" *ngIf="searchQuery != null && searchQuery != ''">
            <div class="users-list-padding position-relative">
                <a class="list-group-item"
                *ngFor="let user of listaBuscarUsuariosChat" (click)="viewChatUsuarioBusqueda(user)">
                <div class="media py-1 align-items-center">
                    <span class="avatar avatar-md mr-2">
                    <img [src]="user.Urlimagen" alt="Avatar">              
                    <i></i>
                    </span>
                    <div class="media-body">
                    <h6 class="list-group-item-heading mb-1">{{user.NombreCompleto.length>18 ? (user.NombreCompleto | slice:0:18)+'...' : user.NombreCompleto}}                
                    </h6>
                    <p class="list-group-item-text chat-user-list-text m-0">                
                        <span class="success">Inicia conversación</span>
                    </p>
                    </div>
                </div>
                </a>        
            </div>
            </div>
            <div [perfectScrollbar] #chatSidebar id="users-list" class="list-group" *ngIf="searchQuery == null || searchQuery == ''">
            <div class="users-list-padding position-relative">
                <a class="list-group-item " [ngClass]="{'selected-chat': chat.isActiveChat}"
                *ngFor="let chat of authService.chats" (click)="viewChat(chat)">
                <div class="media py-1" [ngClass]="{'align-items-center': !chat.isActiveChat}">
                    <span class="avatar avatar-md mr-2">
                    <img [src]="chat.Urlimagen" alt="Avatar">
                    <span class="avatar-status-{{chat.statusChat}}"></span>
                    <i></i>
                    </span>
                    <div class="media-body">
                    <h6 class="list-group-item-heading mb-1">{{chat.NombreChat.length>18 ? (chat.NombreChat | slice:0:18)+'...' : chat.NombreChat}}
                        <span class="font-small-2 float-right chat-time">{{chat.lastChatTime}}</span>
                    </h6>
                    <p class="list-group-item-text chat-user-list-text m-0">
                        <i class="ft-check primary font-small-2 mr-1"
                        *ngIf="chat.lastChatMessage != null && chat.lastChatMessage != ''"></i>
                        <span class="success" 
                        *ngIf="chat.lastChatMessage == null">Inicia conversación</span>
                        <span *ngIf="chat.lastChatMessage != null && chat.lastChatMessage != ''">{{chat.lastChatMessage.length>18 ? (chat.lastChatMessage | slice:0:18)+'...' : chat.lastChatMessage}}</span>
                        <span class="float-right primary">
                        <i class="font-medium-1 icon-pin" *ngIf="chat.isPinnedUser"></i>
                        <i class="font-medium-1 icon-volume-off mr-1" *ngIf="chat.isMuted"></i>
                        <span class="badge badge-pill bg-light-primary badge-round"
                            *ngIf="chat.unreadMessageCount != null && chat.unreadMessageCount != ''">{{chat.unreadMessageCount}}</span>
                        <i class="icon-trash" *ngIf="chat.isActiveChat" (click)="eliminarChat(chat)"></i>  
                        </span>                                 
                    </p>
                    </div>            
                </div>
                </a>
                <div style="margin-top: 90%"><h4 class="success" *ngIf="authService.chats.length == 0" style="text-align: center;">Busca Usuarios para iniciar una nueva conversación</h4></div>        
            </div>
            </div>
        </div>
        <div class="chat-name p-2" style="height: 72px">
            <div class="media p-1 align-items-center">
            <span class="chat-app-sidebar-toggle ft-menu font-medium-4 mr-3 d-block d-md-none"
                (click)="onSidebarToggle()"></span>
            <div class="media-body" *ngIf="authService.chatActivo != null && ((!authService.chatActivo.esChatGrupal && authService.chatActivo.IdUsuarioDestino != null) || authService.chatActivo.esChatGrupal)">
                <img [src]="authService.chatActivo.Urlimagen" width="37" class="avatar mr-1" alt="avatar">
                <span>{{ authService.chatActivo.NombreChat }}</span>
            </div>
            <div class="position-relative float-right">
                <div ngbDropdown display="dynamic" [placement]="placement" class="d-inline-block" *ngIf="authService.chatActivo != null && ((!authService.chatActivo.esChatGrupal && authService.chatActivo.IdUsuarioDestino != null) || authService.chatActivo.esChatGrupal)">
                <i class="ft-more-vertical- mt-1 dropdown-toggle" id="chatOptions" ngbDropdownToggle></i>
                <div ngbDropdownMenu aria-labelledby="chatOptions">
                    <a href="javascript:;" class="dropdown-item">Mute Notification</a>
                    <a href="javascript:;" class="dropdown-item">Clear Chat</a>
                </div>
                </div>
            </div>
            </div>
        </div>
        <section class="chat-app-window position-relative" >
          <div class="chats" *ngIf="authService.chatActivo != null && ((!authService.chatActivo.esChatGrupal && authService.chatActivo.IdUsuarioDestino != null) || authService.chatActivo.esChatGrupal)">
            <div class="chat" [ngClass]="{'chat-left': mensajeChat.IdUsuarioEscribe != authService.usuarioConectado.Usuario.IdUsuario}" 
            *ngFor="let mensajeChat of authService.chatActivo.listaUsuariosChat[indexUsuarioConectado].mensajes">
                <div class="chat-avatar">
                  <a class="avatar avatar-md">
                      <img [src]="mensajeChat.Urlimagen" alt="avatar">
                  </a>
                </div>
                <div class="chat-body">
                  <div class="chat-content" *ngFor="let mensaje of mensajeChat.mensajes; let i = index">
                  
                      <p *ngIf="i == 0 && mensajeChat.IdUsuarioEscribe.toLowerCase() != authService.usuarioConectado.Usuario.IdUsuario.toLowerCase() && authService.chatActivo.esChatGrupal == true">{{ mensajeChat.UsuarioEscribe }}</p>                            
                      <p *ngIf="mensajeChat.tipoMensaje === 'text'">{{ mensaje }}</p>
                          
                  </div>
                </div>
                <p class="time" *ngIf="mensajeChat.time !='' "> <span class="badge badge-secondary mb-1">{{mensajeChat.time}}</span></p>

            </div>
          </div>    
        </section>
        <section class="chat-app-form px-3 py-2" style="height: 72px">
          <div class="d-flex align-items-center" *ngIf="authService.chatActivo != null && ((!authService.chatActivo.esChatGrupal && authService.chatActivo.IdUsuarioDestino != null) || authService.chatActivo.esChatGrupal)">
            <input type="text" class="form-control chat-message-send mr-2"
                placeholder="Escribe tu mensaje aqui" (keydown.enter)="onAddMessage();$event.preventDefault()"
                [(ngModel)]="newMessage" #messageInput>
            <button type="button" class="btn btn-primary d-lg-flex align-items-center" (click)="abrirModalEmoticons(agregarEmoticon)" style="margin-right: 5px; margin-left: -5px;">😀</button>
            <button type="button" class="btn btn-primary d-lg-flex align-items-center" (click)="onAddMessage()">
                <i class="ft-send"></i>
                <span class="d-none d-lg-block ml-1">Enviar</span>
            </button>
          </div>
        </section>  
    </div>
</div>
  