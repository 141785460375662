import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JsonResponse } from "app/oDres/modelos/Datos/jsonResponse";
import { Notificacion } from "app/oDres/modelos/Empresa/notificacion";
import { environment } from "environments/environment";
import { CallService } from "../data/callService";
import { AuthService } from 'app/shared/auth/auth.service';
@Injectable({
    providedIn: 'root'
})
export class NotificacionService extends CallService { 

    constructor(protected http: HttpClient, private authService: AuthService) {
        super(http);
    }

    private urls = {
        obtenerNotificacion: environment.urlApi + '/externo/api/Notificacion/obtener-Notificacion', 
        obtenerNotificacionesCompletas: environment.urlApi + '/externo/api/Notificacion/obtener-Notificaciones-Completas', 
        actualizarNotificacion: environment.urlApi + '/externo/api/Notificacion/actualizar-Notificacion',     
        eliminarNotificacion: environment.urlApi + '/externo/api/Notificacion/eliminar-Notificacion',                   
    }

    obtenerNotificacionesCompletas(notificacion: Notificacion) {

        return this.post<JsonResponse>(this.urls.obtenerNotificacionesCompletas, notificacion, this.authService.token);
    }

    obtenerNotificacion(notificacion: Notificacion) {

        return this.post<JsonResponse>(this.urls.actualizarNotificacion, notificacion, this.authService.token);
    }

    actualizarNotificacion(notificacion: Notificacion) {

        return this.post<JsonResponse>(this.urls.actualizarNotificacion, notificacion, this.authService.token);
    }

    eliminarNotificacion(IdNotificacion) {

        let urlFinal = this.urls.eliminarNotificacion + `?IdNotificacion=${IdNotificacion}`;     

        return this.get<JsonResponse>(urlFinal, this.authService.token);
    }
}