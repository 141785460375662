import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { CallService } from '../data/callService';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Usuario } from 'app/oDres/modelos/Empresa/usuarios';
import { JsonResponse } from 'app/oDres/modelos/Datos/jsonResponse';
import { EmpresaConfiguracionImagenes } from 'app/oDres/modelos/Empresa/empresaConfiguracionImagenes';
import { Documento } from 'app/oDres/modelos/Empresa/documento';
import { TemplateConfig } from 'app/oDres/modelos/Generales/templateConfig';
import { Notificacion } from 'app/oDres/modelos/Empresa/notificacion';
import { NotificacionService } from './notificaciones.service';
import { v4 as uuidv4 } from 'uuid';
@Injectable({
  providedIn: 'root'
})
export class ConfigService extends CallService {
  public templateConf: TemplateConfig = null;
  templateConfSubject = new BehaviorSubject<TemplateConfig>(this.templateConf);
  templateConf$ = this.templateConfSubject.asObservable();
  usuario: Usuario = {} as Usuario;

  constructor(private authService: AuthService,
    private router: Router,
    public local: LocalStorageService,
    public session: SessionStorageService,
    private notificacionService: NotificacionService,
    protected http: HttpClient) {

    super(http);

    this.templateConf = {
      layout: {
        variant: "Light",
        menuPosition: "Side",
        customizer: {
          hidden: true
        },
        navbar: {
          type: 'Static'
        },
        sidebar: {
          collapsed: true,
          size: "sidebar-md",
          backgroundColor: "man-of-steel",
          backgroundImage: true,
          backgroundImageURL: "https://grupoodresdiag.blob.core.windows.net/odes191231/010.jpg"
        }
      }
    };

    console.log('router constructor', this.router.url)
    console.log('usuarioConectado', this.authService.usuarioConectado)
    console.log('Usuario', this.authService.usuarioConectado.Usuario)

    if (this.authService.usuarioConectado?.Usuario == null ||
      this.authService.usuarioConectado?.Usuario == undefined ||
      this.authService.usuarioConectado?.Usuario.IdUsuario == null ||
      this.authService.usuarioConectado?.Usuario.IdUsuario == undefined) {

      //console.log('constructor config.service');        

      let IdEmpresa = sessionStorage.getItem('UsuarioCompletoIdEmpresa')
      console.log('sessionStorage', IdEmpresa)

      if (IdEmpresa == null || this.router.url == '/inicio/login') {        
        
        this.router.navigate(['/inicio/login']);

      } else {

        console.log('Aqui llega else 77')

        const usuarioStorage = this.local.get('UsuarioCompleto_' + IdEmpresa);

        //console.log('usuarioStorage =>', usuarioStorage);

        if (usuarioStorage != null && usuarioStorage != undefined && usuarioStorage != '') {

          this.authService.usuarioConectado = JSON.parse(usuarioStorage);

          const listaUsuariosCompletos = this.local.get('listaUsuariosCompletos_' + IdEmpresa);

          this.authService.token = this.local.get('token_' + IdEmpresa)

          if (listaUsuariosCompletos != null && listaUsuariosCompletos != undefined && listaUsuariosCompletos != '') {

            this.authService.listaUsuarioCompleto = JSON.parse(listaUsuariosCompletos);

          }

          this.usuario.email = this.authService.usuarioConectado.Usuario.email;
          this.usuario.contrasena = this.authService.usuarioConectado.Usuario.contrasena;

          this.setConfigValue(100);

          this.notificacionService.obtenerNotificacionesCompletas({
            IdEmpresa: this.authService.usuarioConectado.Empresa.IdEmpresa,
            IdUsuarioDirigidoA: this.authService.usuarioConectado.Usuario.IdUsuario
          } as Notificacion).subscribe(respuestaNotificacion => {

            //console.log(respuestaNotificacion);

            if (respuestaNotificacion != null && respuestaNotificacion.code == 200) {
              this.authService.usuarioConectado.ListaNotificaciones = respuestaNotificacion.model;
              const numeroAbiertas = this.authService.usuarioConectado.ListaNotificaciones.filter(n => n.Abierta == true).length;
              this.authService.usuarioConectado.NumeroNotificacionesSinVer = numeroAbiertas;
            }

          });

        } else {

          this.router.navigate(['/inicio/login']);

        }

      }

    } else {      

      this.setConfigValue(129);

    }

  }

  setConfigValue(line) {

    //console.log('router', this.router.url)
    //console.log('usuarioConectado', this.authService.usuarioConectado)
    //console.log('setConfigValue =>', this.authService.usuarioConectado);

    console.log('line', line)

    if (this.authService.usuarioConectado.TemplateConf != null) {

      this.templateConf = this.authService.usuarioConectado.TemplateConf;

    } else {

      this.authService.usuarioConectado.TemplateConf = this.templateConf;

      this.authService.usuarioConectado.Configuracion = {
        idEmpresa: this.authService.usuarioConectado.Empresa.IdEmpresa,
        IdUsuario: this.authService.usuarioConectado.Usuario.IdUsuario,
        variant: this.templateConf.layout.variant,
        menuPosition: this.templateConf.layout.menuPosition,
        hidden: this.templateConf.layout.customizer.hidden,
        type: this.templateConf.layout.navbar.type,
        collapsed: this.templateConf.layout.sidebar.collapsed,
        size: this.templateConf.layout.sidebar.size,
        backgroundColor: this.templateConf.layout.sidebar.backgroundColor,
        backgroundImage: this.templateConf.layout.sidebar.backgroundImage,
        backgroundImageUrl: this.templateConf.layout.sidebar.backgroundImageURL,

      };
    }

    this.applyTemplateConfigChange(this.templateConf);

  }

  applyTemplateConfigChange(tempConfig: TemplateConfig) {
    this.templateConf = Object.assign(this.templateConf, tempConfig);
    this.templateConfSubject.next(this.templateConf);
  }

  obtenerUsuario(usuario: Usuario) {

    return this.post<JsonResponse>(environment.urlApi + '/externo/api/usuario/obtener-Usuario', usuario, this.authService.token);
  }

  guardarImagen(empresaConfiguracionImagenes: EmpresaConfiguracionImagenes) {

    return this.post<JsonResponse>(environment.urlApi + '/externo/api/empresaconfiguracionImagenes/agregar-EmpresaConfiguracionImagenes', empresaConfiguracionImagenes, this.authService.token);
  }

  eliminarImagen(empresaConfiguracionImagenes: EmpresaConfiguracionImagenes) {

    return this.post<JsonResponse>(environment.urlApi + '/externo/api/empresaconfiguracionImagenes/eliminar-EmpresaConfiguracionImagenes', empresaConfiguracionImagenes, this.authService.token);
  }

  guardarAvatar(documento: Documento) {

    return this.post<JsonResponse>(environment.urlApi + '/externo/api/usuario/actualizar-Avatar-Usuario', documento, this.authService.token);
  }

  actualizarUsuario(usuario: Usuario) {

    return this.post<JsonResponse>(environment.urlApi + '/externo/api/usuario/actualizar-Usuario', usuario, this.authService.token);
  }

  actualizarContrasenaUsuario(usuario: Usuario) {

    return this.post<JsonResponse>(environment.urlApi + '/externo/api/usuario/actualizar-contrasena-usuario', usuario, this.authService.token);
  }

  inicializarCatalogo(catalogo: Array<any>, asignarElementoInicial: Function, id: string, valor: string, tituloPrimerElemento?: string) {

    const elementoInicial = {
      [id]: 0,
      [valor]: tituloPrimerElemento != null ? tituloPrimerElemento : 'Selecciona una opción'
    };

    catalogo.splice(0, catalogo.length);
    catalogo.push(elementoInicial);

    asignarElementoInicial(elementoInicial);
  }

}
