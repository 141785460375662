

<ng-template #modificarInfoUsuario let-c="close" let-d="dismiss">
  <div class="modal-header" style="margin-top: -10px; padding: 1rem 1rem 0.5rem 1rem;">
    <h5 class="modal-title">Información usuario</h5>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="d-flex col-md-10 mx-auto">
      <img [src]="authService.usuarioConectado.Usuario.Urlimagen" class="avatar mr-2" alt="Avatar" width="150" height="150">
      <div class="align-self-center">
        <h4 class="m-0">{{authService.usuarioConectado.Usuario.Nombre + " " + authService.usuarioConectado.Usuario.Paterno + " " + (authService.usuarioConectado.Usuario.Materno != undefined ? authService.usuarioConectado.Usuario.Materno : '')}}</h4>
        <small class="text-muted font-medium-3">{{authService.usuarioConectado.Usuario.NombreEmpresa}}</small>
        <br />
        <small class="text-muted font-medium-3">{{authService.usuarioConectado.Pais.Nombre}}</small>
        <br />
        <small class="text-muted font-medium-3">{{authService.usuarioConectado.Usuario.email}}</small>
        <br />
        <button type="button" class="btn btn-sm btn-primary" (click)="editarAvatar(modificarAvatar)">Cambiar avatar</button>
      </div>
    </div>

    <br />

    <div class="d-flex col-md-5 mx-auto">    
      <div class="align-self-center">

        <h6 class="card-title" id="basic-layout-form">Cambio contraseña</h6>  

        <div class="form-group" style="margin-top: -10px;">
          <input type="password" class="form-control mb-2" name="password" maxlength="12"
              [ngClass]="{ 'is-invalid': (apretoGuardarContrasena && errorContrasenaActual), 
              'is-valid': (apretoGuardarContrasena && !errorContrasenaActual) }"
              [(ngModel)]="contrasenaActual"
              placeholder="Contraseña Actual" 
              (blur)="confirmarContrasena()">
    
              <div *ngIf="(apretoGuardarContrasena && errorContrasenaActual)" style="font-size: 10pt; margin-top: -10px !important"
                  class="help-block mt-1 text-danger"> 
                  <i class="fas fa-exclamation-circle align-middle"></i> 
                  Debes escribir la contraseña actual.
              </div> 
        </div>
    
        <div class="form-group" style="margin-top: -5px;">
          <input type="password" class="form-control mb-2" name="passwordnuevo" maxlength="12"
          [ngClass]="{ 'is-invalid': (apretoGuardarContrasena && (errorContrasenaNueva || !contrasenaCoincide)), 
          'is-valid': (apretoGuardarContrasena && !errorContrasenaNueva && contrasenaCoincide) }"
          [(ngModel)]="contrasenaNueva"
              placeholder="Contraseña Nueva" 
              (blur)="confirmarContrasena()">
    
              <div *ngIf="(apretoGuardarContrasena && errorContrasenaNueva)" style="font-size: 10pt; margin-top: -10px !important"
                  class="help-block mt-1 text-danger"> 
                  <i class="fas fa-exclamation-circle align-middle"></i> 
                  Debes escribir la contraseña nueva.
              </div>
    
              <div *ngIf="(apretoGuardarContrasena && !contrasenaCoincide)" style="font-size: 10pt; margin-top: -10px !important"
                  class="help-block mt-1 text-danger"> 
                  <i class="fas fa-exclamation-circle align-middle"></i> 
                  La contraseña no coincide con la confirmación
              </div> 
        </div>
    
        <div class="form-group" style="margin-top: -5px;">
          <input type="password" class="form-control mb-2" name="confirmacionpassword" maxlength="12"
          [(ngModel)]="contrasenaNuevaConfirmar"
          [ngClass]="{ 'is-invalid': (apretoGuardarContrasena && (errorContrasenaNuevaCoincidencia || !contrasenaCoincide)), 
          'is-valid': (apretoGuardarContrasena && !errorContrasenaNuevaCoincidencia && contrasenaCoincide) }"
              placeholder="Confirmacion contraseña" 
              (blur)="confirmarContrasena()">
    
          <div *ngIf="(apretoGuardarContrasena && errorContrasenaNuevaCoincidencia)" style="font-size: 10pt; margin-top: -10px !important"
              class="help-block mt-1 text-danger"> 
              <i class="fas fa-exclamation-circle align-middle"></i> 
              Debes escribir la confirmacion de la contraseña nueva.
          </div>     
    
          <div *ngIf="(apretoGuardarContrasena && !contrasenaCoincide)" style="font-size: 10pt; margin-top: -10px !important"
              class="help-block mt-1 text-danger"> 
              <i class="fas fa-exclamation-circle align-middle"></i> 
              La contraseña no coincide con la confirmación
          </div> 
        </div>

      </div>
    </div>  

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary btn-raised" (click)="cambiarContrasena()">Guardar</button>
    <button type="button" class="btn btn-sm btn-secondary btn-raised" (click)="cerrarModalUsuario()">Close</button>
  </div>
</ng-template>

<ng-template #modificarAvatar let-c="close" let-d="dismiss">
  <div class="modal-header" style="margin-top: -10px; padding: 1rem 1rem 0.5rem 1rem;">
    <h4 class="modal-title">Modifica tu avatar</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <!-- <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)" *ngIf="!imageChangedEvent"
      [uploader]="uploader" class="py-5 mb-3 text-center font-medium-5 text-uppercase grey my-drop-zone" (change)="fileChangeEvent($event)">
      Arrasta la imagen aqui
    </div> -->

    <div style="text-align: center;" *ngIf="!imageChangedEvent">
      <label class="font-medium-2 text-uppercase">Arrasta la imagen aqui</label>
    </div>

    <div class="file-container" *ngIf="!imageChangedEvent" >
        <input type="file" class="file" (change)="getFile($event)" style="width:100%"/>              
    </div>
    
    <br *ngIf="!imageChangedEvent">

    <div style="text-align: center;" *ngIf="!imageChangedEvent">
      <label class="font-medium-2 text-uppercase">o Selecciona tu archivo</label>
    </div>

    <div class="row" *ngIf="!imageChangedEvent">                             
      <div class="custom-file mx-auto d-block col-md-8">
        <input type="file" class="custom-file-input" id="inputGroupFile01" (change)="fileChangeEvent($event)" accept="image/png, image/gif, image/jpeg">
        <label class="custom-file-label" for="inputGroupFile01">Seleccion tu archivo</label>
      </div>
    </div>

    <div class="row" *ngIf="imageChangedEvent" style="text-align: center;">
      <div class="col-md-12" style="margin-top: 10px;">
        <button type="button" class="btn btn-primary btn-raised" (click)="rotateLeft()" style="margin-left: 5px; width: 160px;">Gira a la izquierda</button>
        <button type="button" class="btn btn-primary btn-raised" (click)="rotateRight()" style="margin-left: 5px; width: 160px;">Gira a la derecha</button>
        <button type="button" class="btn btn-primary btn-raised" (click)="flipHorizontal()" style="margin-left: 5px; width: 160px;">Gira en horizontal</button>
        <button type="button" class="btn btn-primary btn-raised" (click)="flipVertical()" style="margin-left: 5px; width: 160px;">Gira en vertical</button>
        <button type="button" class="btn btn-warning btn-raised" (click)="cancelCropping()" style="margin-left: 5px; width: 180px;">Cancelar Imagen 
          <i class="ft-x position-right"></i>
        </button>    
      </div>
    </div>

    <div class="row" *ngIf="imageChangedEvent" style="text-align: center;">
      <div class="col-md-6">
        <div class="form-group">
          <table>
            <tr>
              <td>
                <label class="font-medium-2 text-uppercase">Rotar</label>
              </td>
              <td>
                <input type="number" class="form-control" min="0" max="360" maxlength="3" style="margin-left: 5px; width: 70px"
                    [(ngModel)]="rotation" [ngModelOptions]="{standalone: true}" (ngModelChange)="revisarRotation()">
              </td>
              <td>
                <nouislider [min]="0" [max]="360" [step]="1" [(ngModel)]="rotation" (ngModelChange)="updateRotation()" style="width: 300px; margin-top: 50px; margin-left: 5px;">
                </nouislider>
              </td>              
            </tr>
          </table>          
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <table>
            <tr>
              <td>
                <label class="font-medium-2 text-uppercase">Zoom</label>
              </td>
              <td>
                <input type="number" class="form-control" min="-10" max="10" maxlength="2" style="margin-left: 5px; width: 70px"
                    [(ngModel)]="scale" [ngModelOptions]="{standalone: true}" (ngModelChange)="revisarZoom()">
              </td>           
              <td>
                <nouislider [min]="-10" [max]="10" [step]="0.1" [(ngModel)]="scale" (ngModelChange)="zoomChange()" style="width: 300px; margin-top: 50px; margin-left: 5px;">
                </nouislider>
              </td>              
            </tr>
          </table>          
        </div>
      </div>
    </div>

    <!-- <div class="row" *ngIf="imageChangedEvent" style="text-align: center;">
      <input [(ngModel)]="rotation" placeholder="Rotation" type="number" (keyup)="updateRotation()" *ngIf="imageChangedEvent"/> <button (click)="zoomOut()" *ngIf="imageChangedEvent">Zoom -</button> <button (click)="zoomIn()" *ngIf="imageChangedEvent">Zoom +</button>
      <button type="button" *ngIf="imageChangedEvent" 
          class="btn mr-1 btn-raised btn-warning col-md-3 mx-auto" (click)="cancelCropping()">Cancelar Imagen 
          <i class="ft-x position-right"></i>
      </button>
    </div> -->

    <div class="row">                               
      <div class="col-md-6" style="margin-top: 10px; text-align: center;">
        <!-- <image-cropper [imageChangedEvent]="imageChangedEvent" 
                        [maintainAspectRatio]="true" 
                        [aspectRatio]="4 / 3"
                        [resizeToWidth]="512"
                        [cropperMinWidth]="128"
                        [onlyScaleDown]="true"
                        roundCropper = true
                        format="png" 
                        (imageCropped)="imageCropped($event)" 
                        (imageLoaded)="imageLoaded()"
                        (cropperReady)="cropperReady()" 
                        (loadImageFailed)="loadImageFailed()" 
                        class="float-left mr-4 img-fluid col-md-3"
                        [style.display]="showCropper ? null : 'none'">
        </image-cropper> -->
        <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [containWithinAspectRatio]="false"
            [resizeToWidth]="256"
            [cropperMinWidth]="128"
            [onlyScaleDown]="true"
            [roundCropper]="true"
            [canvasRotation]="canvasRotation"
            [transform]="transform"
            [alignImage]="'left'"
            [style.display]="showCropper ? null : 'none'"
            format="png"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()"
            (loadImageFailed)="loadImageFailed()"            
        >
        </image-cropper>
      </div>
      <br>
      <div class="col-md-6" style="margin-top: 10px; text-align: center;" *ngIf="showCropper">
        <span class="result">
          <img [src]="croppedImage" style="width: 360px; border-radius: 100%;"/>           
        </span>
      </div>
      
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-raised" (click)="guardarImagen()">Guardar</button>
    <button type="button" class="btn btn-warning btn-raised" (click)="c('Close click')">Close</button>
  </div>
</ng-template>

<ng-template #verNotificacionModal let-c="close" let-d="dismiss">
  <div class="modal-header" style="margin-top: -10px">
    <h4 class="modal-title">{{notificacionSeleccionada.Titulo}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-actions">
      <div class="row">
        <div class="col-md-12">
            <div class="form-group">
              <label for="projectinput1" style="text-transform: none;">{{notificacionSeleccionada.Descripcion}}</label>                
            </div>
        </div>            
      </div>
    </div>
  </div>
  <div class="modal-footer">    
    <button type="button" class="btn btn-secondary btn-raised" (click)="c('Close click')">Close</button>
  </div>
</ng-template>

<ng-template #empresas>
  <div class="modal-header">
    <h4 class="modal-title">Selecciona la empresa</h4>
  </div>
  <div class="modal-body">
    <div class="form-actions">
      <div class="row">     
        <ng-select [items]="filteredlistaEmpresa | async"
                    bindLabel="Nombre"
                    autofocus
                    bindValue="IdEmpresa"
                    [(ngModel)]="authService.IdEmpresaSeleccionada"
                    [ngModelOptions]="{standalone: true}"
                    style="width: 300px; margin-left: 80px;"
                    >
        </ng-select>
      </div>
    </div>

    <div style="height: 200px;"></div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-warning btn-raised" (click)="cancelarCambioEmpresa()">Cancelar</button>
    <button type="button" class="btn btn-secondary btn-raised" (click)="seleccionarEmpresa()">Acceder</button>
  </div>
</ng-template>

<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
  [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="fas fa-bars font-medium-3"></i>
      </div>
      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <li class="nav-item d-none d-lg-block mr-2 mt-1">
          <div class="user d-md-flex d-none mr-2">              
            <span 
            [style.color]="authService.usuarioConectado.TemplateConf.layout.variant == 'Light' ? 'rgb(149, 146, 12)' : 'rgb(232, 238, 149)'"
            style="font-size: 12pt; font-weight: bold;">
              {{titleService.getTitle()}}
            </span>
          </div>
        </li>
        <!-- <li class="nav-item mr-2 d-none d-lg-block">
          <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
            (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
        </li>
        <li class="nav-item nav-search">
          <a class="nav-link nav-link-search" id="navbar-search" href="javascript:" (click)="toggleSearchOpenClass(true)"><i
              class="fas fa-search font-medium-3"></i></a>
          <div class="search-input {{searchOpenClass}}">
            <div class="search-input-icon">
              <i class="fas fa-search font-medium-3"></i>
            </div>
            <input [formControl]="control" #search class="input" type="text" placeholder="Explore Apex..." tabindex="0"
              autofocus data-search="template-search" [appAutocomplete]="autocomplete" (keyup.enter)="onEnter()" (keydown.esc)="onEscEvent()" (keyup)="onSearchKey($event)" />
            <div class="search-input-close" (click)="toggleSearchOpenClass(false)">
              <i class="ft-x font-medium-3"></i>
            </div>

            <app-autocomplete #autocomplete="appAutocomplete">
              <ng-template appAutocompleteContent >
                <ng-container *ngIf="(listItems | filter: control.value) as result">
                  <app-option #searchResults *ngFor="let option of result" [url]="option.url" [value]="option.name" (click)="redirectTo(option.url);" (mouseenter)="removeActiveClass()"  >
                    <div class="d-flex align-items-center justify-content-between w-100">
                      <div class="d-flex align-items-center justify-content-start">
                        <i class="{{option.icon}} mr-2"></i>
                        <span>{{ option.name }}</span>
                      </div>
                    </div>
                  </app-option>
                  <app-option class="no-result" *ngIf="!result.length">No results found.</app-option>
                </ng-container>
              </ng-template>
            </app-autocomplete>
          </div>
        </li> -->
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo">
                <a class="logo-text" style="width: 300px;" [routerLink]="['/empresa/informacion-empresa']" *ngIf="config?.layout.menuPosition === 'Top'">
                  <div class="logo-img"><img class="logo-img" alt="Apex logo" [src]="logoUrl"></div>
                  <span class="text" 
                  *ngIf="authService.usuarioConectado != null && authService.usuarioConectado.Empresa != null"
                  style="font-size: 12pt;"       
                  >
                  {{authService.usuarioConectado.Empresa.Nombre}}</span>
                  <!-- <span class="text">{{authService.usuarioConectado.Empresa.Nombre}}</span> -->
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <!-- <li class="i18n-dropdown dropdown nav-item mr-2" display="dynamic" ngbDropdown >
            <a class="nav-link d-flex align-items-center dropdown-toggle dropdown-language" id="dropdown-flag"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle><img class="langimg selected-flag"
                [src]="selectedLanguageFlag" alt="flag" /><span
                class="selected-language d-md-flex d-none">{{selectedLanguageText}}</span></a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left" aria-labelledby="dropdown-flag">
              <a class="dropdown-item" href="javascript:;" data-language="en" (click)="ChangeLanguage('en')">
                <img class="langimg mr-2" src="./assets/img/flags/us.png" alt="flag" />
                  <span class="font-small-3">English</span>
              </a>
              <a class="dropdown-item" href="javascript:;" data-language="es" (click)="ChangeLanguage('es')">
                <img class="langimg mr-2" src="./assets/img/flags/es.png" alt="flag" />
                  <span class="font-small-3">Spanish</span>
              </a>
              <a class="dropdown-item" href="javascript:;" data-language="pt" (click)="ChangeLanguage('pt')">
                <img class="langimg mr-2" src="./assets/img/flags/pt.png" alt="flag" />
                <span class="font-small-3">Portuguese</span>
              </a>
              <a class="dropdown-item" href="javascript:;" data-language="de" (click)="ChangeLanguage('de')">
                <img class="langimg mr-2" src="./assets/img/flags/de.png" alt="flag" />
                <span class="font-small-3">German</span>
              </a>
            </div>
          </li> -->
          <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <div class="user d-md-flex d-none mr-2">              
              <span class="text-right" *ngIf="authService.usuarioConectado != null && authService.usuarioConectado.Empresa.Activo">
                Fecha: <span class="text-right text-muted font-small-3" 
                [style.color]="authService.usuarioConectado.TemplateConf.layout.variant == 'Light' ? 'green !important' : 'lightgreen !important'"
                >{{today | date:'dd/MM/yyyy'}}</span> 
              </span>
            </div>
          </li>
          <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <div class="user d-md-flex d-none mr-2">              
              <span class="text-right" *ngIf="authService.usuarioConectado != null && authService.usuarioConectado.Empresa.Activo">
                Tipo de Licencia: <span class="text-right text-muted font-small-3" 
                [style.color]="authService.usuarioConectado.TemplateConf.layout.variant == 'Light' ? 'green !important' : 'lightgreen !important'"
                >{{authService.usuarioConectado.TipoLicencia.Licencia}}</span> 
              </span>
            </div>
          </li>
          <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <div class="user d-md-flex d-none mr-2">              
              <span class="text-right" *ngIf="authService.usuarioConectado != null && authService.usuarioConectado.Empresa.Activo && authService.usuarioConectado.TipoLicencia.NomenclaturaLicencia != 'ILI'">
                Licencia Valida hasta: <span class="text-right text-muted font-small-3" 
                [style.color]="authService.usuarioConectado.TemplateConf.layout.variant == 'Light' ? 'green !important' : 'lightgreen !important'">{{authService.usuarioConectado.Empresa.FechaLicenciaVigencia | date:'dd/MM/yyyy'}}</span> 
              </span>
              <span class="text-right" *ngIf="authService.usuarioConectado != null && !authService.usuarioConectado.Empresa.Activo && authService.usuarioConectado.TipoLicencia.NomenclaturaLicencia != 'ILI'">
                Licencia Expirada el dia: <span class="text-right text-muted font-small-3" style="color: lightcoral !important">{{authService.usuarioConectado.Empresa.FechaLicenciaVigencia | date:'dd/MM/yyyy'}}</span> 
              </span>
            </div>
          </li>
          <li class="dropdown nav-item" placement="bottom-left" display="static" >
            <a class="nav-link dropdown-toggle dropdown-notification p-0 mt-2" id="drp-notification" 
            (click)="mostrarNotificaciones == true ? mostrarNotificaciones = false : mostrarNotificaciones = true">
              <i class="far fa-bell font-medium-3"
              [style.margin-right]="authService.usuarioConectado.NumeroNotificacionesSinVer == 0 ? '6px' : null"></i>
              <span *ngIf="authService.usuarioConectado.NumeroNotificacionesSinVer > 0"
                class="notification badge badge-pill badge-danger">{{authService.usuarioConectado.NumeroNotificacionesSinVer}}</span>
            </a>
            <ul [style.display] = "mostrarNotificaciones == true ? 'block' : 'none'"
              class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden">

              <li class="dropdown-menu-header" *ngIf="authService.usuarioConectado.NumeroNotificacionesSinVer > 0">
                <div class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary">
                  <div class="d-flex cursor-pointer">
                    <i class="far fa-bell font-medium-3 d-flex align-items-center mr-2"></i><span class="noti-title">{{authService.usuarioConectado.NumeroNotificacionesSinVer}} Nuevas </span>
                  </div>
                  <span class="text-bold-400 cursor-pointer" (click)="marcarTodasComoLeidas()">Marcar todas como leidas</span>
                </div>
              </li>
              <li class="scrollable-container" [perfectScrollbar]>
                <a class="d-flex justify-content-between" *ngIf="authService.usuarioConectado.ListaNotificaciones.length == 0">
                  <div class="media d-flex align-items-center">                   
                    <div class="media-body">                      
                      <h6 class="noti-text font-small-3 m-0" style="margin-top: 5px !important;text-align: center;">
                        Sin Notificaciones
                      </h6>
                    </div>
                  </div>
                </a>

                <a class="d-flex justify-content-between" *ngFor="let notificacion of authService.usuarioConectado.ListaNotificaciones; let i=index"
                [class.read-notification] = "notificacion.Abierta">
                  <div class="media d-flex align-items-center">
                    <!-- <div class="media-left">
                      <div class="mr-3">
                        <img class="avatar" src="assets/img/portrait/small/avatar-s-20.png" alt="avatar" height="45"
                          width="45" />
                      </div>
                    </div> -->
                    <div class="media-body">
                      <h6 class="m-0" *ngIf="notificacion.Abierta" style="margin-bottom: 3px !important; margin-top: -8px !important;" (click)="abrirNotificacion(notificacion, verNotificacionModal)">
                        <span class="font-italic" style="color: red">Nueva Notificación</span>
                      </h6>
                      <table style="width: 100%;">
                        <tr>
                          <td style="width: 70%;">
                            <!-- <h6 class="m-0" [style.margin-top]="notificacion.Abierta == false ? '-7px !important' : null">
                              <span>{{notificacion.Titulo}}</span><small class="grey lighten-1 font-italic float-right">{{notificacion.FechaRegistro | date:'shortTime'}}</small>
                            </h6> -->
                            <h6 class="m-0" [style.margin-top]="notificacion.Abierta == false ? '-7px !important' : null" (click)="abrirNotificacion(notificacion, verNotificacionModal)">
                              <span>{{notificacion.Titulo}}</span>
                            </h6>
                          </td>
                          <td>
                            <h6 class="m-0" [style.margin-top]="notificacion.Abierta == false ? '-7px !important' : null" (click)="abrirNotificacion(notificacion, verNotificacionModal)">
                              <small class="grey lighten-1 font-italic float-right">{{notificacion.FechaNotificacion}}</small>
                            </h6>
                          </td>
                          <td>
                            <i class="ft-trash-2 danger cursor-pointer float-right" [style.margin-top]="notificacion.Abierta == false ? '-7px !important' : null" (click)="eliminarNotificacion(notificacion);"></i>
                          </td>
                        </tr>
                      </table>
                      
                      <!-- <small class="noti-text"></small> -->
                      <h6 class="noti-text font-small-3 m-0" style="margin-top: 5px !important" (click)="abrirNotificacion(notificacion, verNotificacionModal)">
                        {{ (notificacion.Descripcion.length>30)? (notificacion.Descripcion | slice:0:30)+'...':(notificacion.Descripcion) }}
                      </h6>
                    </div>
                  </div>
                </a>                
              </li>
              <li class="dropdown-menu-footer">
                <div class="noti-footer text-center cursor-pointer primary border-top text-bold-400 py-1" (click)="mostrarNotificaciones = false">
                  Cerrar notificaciones
                </div>
              </li>
              <!-- <li class="dropdown-menu-footer" *ngIf="authService.usuarioConectado.ListaNotificaciones.length > 0">
                <div class="noti-footer text-center cursor-pointer primary border-top text-bold-400 py-1">
                  Read All Notifications
                </div>
              </li> -->
            </ul>
          </li>
          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right" *ngIf="authService.usuarioConectado != null && authService.usuarioConectado.Usuario != null">
                  {{authService.usuarioConectado.Usuario.NombreCompleto}}
                </span>
                <span class="text-right text-muted font-small-3" 
                [style.color]="authService.usuarioConectado.TemplateConf.layout.variant == 'Light' ? 'green !important' : 'lightgreen !important'"
                >Disponible</span>
              </div>
              <img class="avatar" *ngIf="authService.usuarioConectado != null && authService.usuarioConectado.Usuario != null" 
              [src]="authService.usuarioConectado.Usuario.Urlimagen" alt="avatar" height="35" width="35" />
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>
              <!-- <a class="dropdown-item" (click)="editarInformacion(modificarInfoUsuario)">
                <div class="d-flex align-items-center">
                  <i class="far fa-edit mr-2"></i><span>Editar información</span>
                </div>
              </a> -->
              <a class="dropdown-item" (click)="misInformacion()">
                <div class="d-flex align-items-center">
                  <i class="far fa-user mr-2"></i><span>Mi información</span>
                </div>
              </a>
              <a class="dropdown-item" (click)="misTareas()" 
              *ngIf="authService.usuarioConectado.TipoLicencia.NomenclaturaLicencia == 'PRE' || authService.usuarioConectado.TipoLicencia.EsIlimitada">
                <div class="d-flex align-items-center">
                  <i class="fas fa-tasks mr-2"></i><span>Mis Tareas</span>
                </div>
              </a>
              <a class="dropdown-item" (click)="adminTareas()"
              *ngIf="(authService.usuarioConectado.TipoLicencia.NomenclaturaLicencia == 'PRE' 
              || authService.usuarioConectado.TipoLicencia.EsIlimitada) && authService.usuarioConectado.Perfil.esAdministrador">
                <div class="d-flex align-items-center">
                  <i class="fas fa-tools mr-2"></i><span>Administrar Tareas</span>
                </div>
              </a>
              <a class="dropdown-item" (click)="cambiarEmpresa(empresas)" *ngIf="authService.listaUsuarioCompleto.length > 1">
                <div class="d-flex align-items-center">
                  <i class="fas fa-exchange-alt mr-2"></i><span>Cambiar empresa</span>
                </div>
              </a>
              <!-- <a class="dropdown-item" routerLink="/inbox">
                <div class="d-flex align-items-center">
                  <i class="far fa-envelope mr-2"></i><span>Mi inbox</span>
                </div>
              </a> -->
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="cerrarSesion()">
                <div class="d-flex align-items-center">
                  <i class="fas fa-power-off mr-2"></i><span>Cerrar Sesión</span>
                </div>
              </a>
            </div>
          </li>
          <!-- <li class="nav-item d-none d-lg-block mr-2 mt-1">
            <a class="nav-link notification-sidebar-toggle" (click)="toggleNotificationSidebar();"><i
                class="fas fa-align-right font-medium-3"></i></a>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</nav>
