import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EstatusTarea } from "app/oDres/modelos/Catalogos/estatusTarea";
import { JsonResponse } from "app/oDres/modelos/Datos/jsonResponse";
import { CatalogoPagosCobranza } from "app/oDres/modelos/Empresa/catalogoPagosCobranza";
import { Consecutivo } from "app/oDres/modelos/Empresa/consecutivo";
import { PagoClienteCompleto } from "app/oDres/modelos/Cobranza/pagoClienteCompleto";
import { Tareas } from "app/oDres/modelos/Empresa/tareas";
import { TareasXDocumento } from "app/oDres/modelos/Empresa/tareasXDocumento";
import { TareasXNotas } from "app/oDres/modelos/Empresa/tareasXNotas";
import { Usuario } from "app/oDres/modelos/Empresa/usuarios";
import { ChatGrupal } from "app/oDres/modelos/Generales/chatGrupal";
import { Chats } from "app/oDres/modelos/Generales/chats";
import { CallService } from "app/shared/data/callService";
import { ConfigService } from "app/shared/services/config.service";
import { environment } from "environments/environment";
import { AsyncSubject, Observable } from 'rxjs';
import { CatalogoPagosProveedor } from "app/oDres/modelos/Empresa/catalogosPagosProveedor";
import { PagoProveedorCompleto } from "app/oDres/modelos/PagoProveedor/pagoProveedorCompleto";
import { Documento } from "app/oDres/modelos/Empresa/documento";
import { Producto } from "app/oDres/modelos/Catalogos/producto";
import { Tercero } from "app/oDres/modelos/Catalogos/tercero";
import { Domicilio } from "app/oDres/modelos/Domicilio/domicilio";
import { Banco } from "app/oDres/modelos/Catalogos/banco";
import { Bancos } from "app/oDres/modelos/Empresa/banco";
import { Vendedor } from "app/oDres/modelos/Empresa/vendedor";
import { Comprador } from "app/oDres/modelos/Empresa/comprador";
import { RegistroOrdenesCompraEmpresa } from "app/oDres/modelos/Empresa/registroOrdenesCompraEmpresa";
import { TipoLicencia } from "app/oDres/modelos/Empresa/tipoLicencia";

export interface SelectedFiles {
    id?: number;
    name: string;
    file: any;
    base64?: string;
    url?: string;
  }

@Injectable({
    providedIn: 'root'
})
export class GeneralesService extends CallService {

    imageChangedEvent: any = '';
    catalogosPagosCobranza: CatalogoPagosCobranza;
    catalogosPagosProveedor: CatalogoPagosProveedor;
    ProductoBusqueda: Producto = null;
    VendedorBusqueda: Vendedor = null;    
    CompradorBusqueda: Comprador = null;
    tareaService: Tareas = {};
    croppedImage: any = '';
    fileName = '';
    descripcionMenu = '';
    terceroBusqueda: Tercero = null;
    bancoBusqueda: Banco = null;
    esClienteOProveedor: string = null;

    constructor(protected http: HttpClient, private config: ConfigService) {
        super(http);
    }

    private urlsServicioDireccion = {
        obtenerColoniasXCP: environment.urlServicioDireccion,
        //obtenerColoniasXCP: environment.urlServicioDireccion + '/get_colonia_por_cp/',
        //obtenerInfoXCP: environment.urlServicioDireccion + '/info_cp/',
    };

    private urls = {        
        obtenerTareas: environment.urlApi + '/externo/api/Tareas/obtener-Tareas',  
        agregarTareas: environment.urlApi + '/externo/api/Tareas/agregar-Tareas',  
        actualizarTareas: environment.urlApi + '/externo/api/Tareas/actualizar-Tareas',   
        actualizarTareasAdministrador: environment.urlApi + '/externo/api/Tareas/actualizar-Tareas-Administrador',       
        obtenerTareasXUsuario: environment.urlApi + '/externo/api/Tareas/obtenerTareasXUsuario',
        obtenerUsuario: environment.urlApi + '/externo/api/usuario/obtener-Usuario',
        obtenerUsuarioPaginado: environment.urlApi + '/externo/api/usuario/obtener-paginado-Usuario',
        obtenerUsuarioAgrupado: environment.urlApi + '/externo/api/usuario/obtener-UsuarioAgrupado',  
        obtenerChatsUsuario: environment.urlApi + '/externo/api/usuario/obtener-Chats-Usuario',
        obtenerEstatusTarea: environment.urlApi + '/externo/api/EstatusTarea/obtener-EstatusTarea',    
        eliminarTarea: environment.urlApi + '/externo/api/Tareas/eliminar-Tareas',
        agregarActualizarChats: environment.urlApi + '/externo/api/Chats/agregar-actualizar-Chats',
        eliminarChats: environment.urlApi + '/externo/api/Chats/eliminar-Chats',
        obtenerTareasXUsuariosAdmin: environment.urlApi + '/externo/api/Tareas/obtenerTareasXUsuariosAdmin',
        agregarTareaXNota: environment.urlApi + '/externo/api/TareasXNotas/agregar-TareasXNotas',
        agregarTareaXDocumento: environment.urlApi + '/externo/api/TareasXDocumento/agregar-TareasXDocumento',
        eliminarDocumento: environment.urlApi + '/externo/api/TareasXDocumento/eliminar-TareasXDocumento',
        verificarCatalogosPagosCobranza: environment.urlApi + '/externo/api/catalogos/verificar-catalogos-Pagoscobranza',
        verificarCatalogosPagosProveedor: environment.urlApi + '/externo/api/catalogos/verificar-catalogos-Pagosproveedor',
        obtenerConsecutivo: environment.urlApi + '/externo/api/consecutivo/obtener-Consecutivo',
        agregarPagoClienteCompleto: environment.urlApi + '/externo/api/pagoClienteCompleto/agregar-PagoClienteCompleta',
        actualizarPagoClienteCompleto: environment.urlApi + '/externo/api/pagoClienteCompleto/actualizar-PagoClienteCompleta',
        agregarPagoProveedorCompleto: environment.urlApi + '/externo/api/pagoProveedorCompleto/agregar-PagoProveedorCompleta',
        actualizarPagoProveedorCompleto: environment.urlApi + '/externo/api/pagoProveedorCompleto/actualizar-PagoProveedorCompleta',
        obtenerCobranzaDistribucionSaldosAbonos: environment.urlApi + '/externo/api/pagoClienteDetalle/obtener-CobranzaDistribucionSaldosAbonos',
        obtenerCuentaXPagarDistribucionSaldosPagos: environment.urlApi + '/externo/api/pagoProveedorDetalle/obtener-CuentaXPagarDistribucionSaldosPagos',
        verificarCatalogosProducto: environment.urlApi + '/externo/api/catalogos/verificar-catalogos-producto',
        actualizarProducto: environment.urlApi + '/externo/api/Producto/actualizar-Producto',
        agregarProducto: environment.urlApi + '/externo/api/Producto/agregar-Producto',
        actualizarTercero: environment.urlApi + '/externo/api/Tercero/actualizar-Tercero',
        agregarEmpresaTercero: environment.urlApi + '/externo/api/Tercero/agregar-Tercero',
        verificarCatalogosTerceros: environment.urlApi + '/externo/api/catalogos/verificar-catalogos-terceros',
        obtenerTercero: environment.urlApi + '/externo/api/Tercero/obtener-Tercero',
        verificarCatalogosBancos: environment.urlApi + '/externo/api/catalogos/verificar-catalogos-bancos',
        actualizarBancos: environment.urlApi + '/externo/api/bancos/actualizar-Bancos',
        agregarBancos: environment.urlApi + '/externo/api/bancos/agregar-Bancos',
        obtenerVendedor: environment.urlApi + '/externo/api/vendedor/obtener-Vendedor',
        actualizarVendedor: environment.urlApi + '/externo/api/vendedor/actualizar-Vendedor',
        agregarVendedor: environment.urlApi + '/externo/api/vendedor/agregar-Vendedor',
        obtenerComprador: environment.urlApi + '/externo/api/Comprador/obtener-Comprador',
        obtenerCompradorPaginado: environment.urlApi + '/externo/api/Comprador/obtener-paginado-Comprador',
        agregarComprador: environment.urlApi + '/externo/api/Comprador/agregar-Comprador',
        actualizarComprador: environment.urlApi + '/externo/api/Comprador/actualizar-Comprador',
        obtenerDomicilio: environment.urlApi + '/externo/api/Utilidades/obtener-Domicilio',
        cargoConRedireccionamientoRenovacionLicenciaXEmpresa: environment.urlApi + '/externo/api/RegistroOrdenesCompraEmpresa/cargo-ConRedireccionamientoRenovacionLicenciaXEmpresa',
        obtenerTipoLicencia: environment.urlApi + '/externo/api/TipoLicencia/obtener-TipoLicencia'
    }

    obtenerTipoLicencia(tipoLicencia?: TipoLicencia) {
        return this.post<JsonResponse>(this.urls.obtenerTipoLicencia, tipoLicencia);
    }

    cargoConRedireccionamientoRenovacionLicenciaXEmpresa(registroOrdenesCompraEmpresa: RegistroOrdenesCompraEmpresa) {
        return this.post<JsonResponse>(this.urls.cargoConRedireccionamientoRenovacionLicenciaXEmpresa, registroOrdenesCompraEmpresa);
    }

    agregarComprador(vendedor: Comprador) {

        return this.post<JsonResponse>(this.urls.agregarComprador, vendedor);
    }

    actualizarComprador(vendedor: Comprador) {

        return this.post<JsonResponse>(this.urls.actualizarComprador, vendedor);
    }

    obtenerComprador(vendedor?: Comprador) {
        return this.post<JsonResponse>(this.urls.obtenerComprador, vendedor);
    }

    obtenerCompradorPaginado(tamanioPagina: number, pagina: number, idEmpresa: string) {

        let urlFinal = this.urls.obtenerCompradorPaginado + `?tamanioPagina=${tamanioPagina}&pagina=${pagina}&IdEmpresa=${idEmpresa}`;

        return this.get<JsonResponse>(urlFinal);
    }

    agregarVendedor(vendedor: Vendedor) {

        return this.post<JsonResponse>(this.urls.agregarVendedor, vendedor);
    }

    actualizarVendedor(vendedor: Vendedor) {

        return this.post<JsonResponse>(this.urls.actualizarVendedor, vendedor);
    }

    obtenerVendedor(vendedor?: Vendedor) {
        return this.post<JsonResponse>(this.urls.obtenerVendedor, vendedor);
    }

    agregarBancos(bancos: Bancos) {

        return this.post<JsonResponse>(this.urls.agregarBancos, bancos);
    }

    actualizarBancos(bancos: Bancos) {

        return this.post<JsonResponse>(this.urls.actualizarBancos, bancos);
    }

    verificarCatalogosBancos(IdEmpresa: any) {

        console.log('verificarCatologBancos');

        const urlFinal = this.urls.verificarCatalogosBancos + `?idEmpresa=${IdEmpresa}`;

        return this.get<JsonResponse>(urlFinal);
    }

    obtenerTercero(tercero: Tercero) {
        return this.post<JsonResponse>(this.urls.obtenerTercero, tercero);
    }

    verificarCatalogosTerceros(IdPais: any, IdEmpresa: any) {

        console.log('verificarCatologTercero');

        const urlFinal = this.urls.verificarCatalogosTerceros + `?idPais=${IdPais}&idEmpresa=${IdEmpresa}`;

        return this.get<JsonResponse>(urlFinal);
    }

    obtenerColoniasXCP(cp: string) {
        
        return this.get<JsonResponse>(this.urls.obtenerDomicilio + `?CP=${cp}`);
    }

    agregarEmpresaTercero(tercero: Tercero) {

        return this.post<JsonResponse>(this.urls.agregarEmpresaTercero, tercero);
    }

    actualizarTercero(tercero: Tercero) {

        return this.post<JsonResponse>(this.urls.actualizarTercero, tercero);
    }
    
    agregarProducto(producto: Producto) {

        return this.post<JsonResponse>(this.urls.agregarProducto, producto);
    }

    actualizarProducto(producto: Producto) {

        return this.post<JsonResponse>(this.urls.actualizarProducto, producto);
    }

    verificarCatalogosProductos(IdEmpresa: any) {

        console.log('verificarCatologosProductos');

        const urlFinal = this.urls.verificarCatalogosProducto + `?idEmpresa=${IdEmpresa}`;

        return this.get<JsonResponse>(urlFinal);
    }

    public toBase64(files: File[], selectedFiles: SelectedFiles[]): Observable<SelectedFiles[]> {
        const result = new AsyncSubject<SelectedFiles[]>();
        let count = 0;
        if (files?.length) {
          Object.keys(files)?.forEach((file, i) => {
            const url = URL.createObjectURL(files[i]);
            const reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onload = (e) => {
              selectedFiles = selectedFiles?.filter(f => f?.name != files[i]?.name);
              count++;
              selectedFiles.push({id: count, name: files[i]?.name, file: files[i], base64: reader?.result as string, url: url});
              result.next(selectedFiles);
              if (files?.length === (i + 1)) {
                result.complete();
              }
            };
          });
          return result;
        } else {
          result.next([]);
          result.complete();
          return result;
        }
      }

      actualizarContrasenaUsuario(usuario: Usuario) {

        return this.post<JsonResponse>(environment.urlApi + '/externo/api/usuario/actualizar-contrasena-usuario', usuario);
      }

      guardarAvatar(documento: Documento) {

        return this.post<JsonResponse>(environment.urlApi + '/externo/api/usuario/actualizar-Avatar-Usuario', documento);
      }

      obtenerCuentaXPagarDistribucionSaldosPagos(idPagoProveedor: any) {        

        let urlFinal = this.urls.obtenerCuentaXPagarDistribucionSaldosPagos + `?idPagoProveedor=${idPagoProveedor}`;       

        return this.get<JsonResponse>(urlFinal);        
    }

    obtenerCobranzaDistribucionSaldosAbonos(idPagoCliente: any) {        

        let urlFinal = this.urls.obtenerCobranzaDistribucionSaldosAbonos + `?idPagoCliente=${idPagoCliente}`;       

        return this.get<JsonResponse>(urlFinal);        
    }

    agregarPagoClienteCompleto(pagoClienteCompleto: PagoClienteCompleto) {

        return this.post<JsonResponse>(this.urls.agregarPagoClienteCompleto, pagoClienteCompleto);
    }

    actualizarPagoClienteCompleto(pagoClienteCompleto: PagoClienteCompleto) {

        return this.post<JsonResponse>(this.urls.actualizarPagoClienteCompleto, pagoClienteCompleto);
    }

    agregarPagoProveedorCompleto(pagoProveedorCompleto: PagoProveedorCompleto) {

        return this.post<JsonResponse>(this.urls.agregarPagoProveedorCompleto, pagoProveedorCompleto);
    }

    actualizarPagoProveedorCompleto(pagoProveedorCompleto: PagoProveedorCompleto) {

        return this.post<JsonResponse>(this.urls.actualizarPagoProveedorCompleto, pagoProveedorCompleto);
    }

    obtenerConsecutivo(consecutivo: Consecutivo) {

        return this.post<JsonResponse>(this.urls.obtenerConsecutivo, consecutivo);
    }

    verificarCatalogosPagosCobranza(IdPais: any, IdEmpresa: any) {

        const urlFinal = this.urls.verificarCatalogosPagosCobranza + `?idPais=${IdPais}&idEmpresa=${IdEmpresa}`;

        return this.get<JsonResponse>(urlFinal);
    }

    verificarCatalogosPagosProveedor(IdPais: any, IdEmpresa: any) {

        const urlFinal = this.urls.verificarCatalogosPagosProveedor + `?idPais=${IdPais}&idEmpresa=${IdEmpresa}`;

        return this.get<JsonResponse>(urlFinal);
    }

    eliminarChats(chats: Chats) {

        return this.post<JsonResponse>(this.urls.eliminarChats, chats);
    }

    obtenerChatsUsuario(idEmpresa: string, idUsuarioConectado) {

        let urlFinal = this.urls.obtenerChatsUsuario + `?idEmpresa=${idEmpresa}&idUsuarioConectado=${idUsuarioConectado}`;       

        return this.get<JsonResponse>(urlFinal);
    }

    obtenerUsuario(usuario: Usuario) {

        return this.post<JsonResponse>(this.urls.obtenerUsuario, usuario);
    }

    agregarActualizarChats(chats: Chats) {

        return this.post<JsonResponse>(this.urls.agregarActualizarChats, chats);
    }

    eliminarTarea(tarea: Tareas) {

        return this.post<JsonResponse>(this.urls.eliminarTarea, tarea);
    }

    obtenerEstatusTarea(estatusTarea: EstatusTarea) {

        return this.post<JsonResponse>(this.urls.obtenerEstatusTarea, estatusTarea);
    }

    obtenerUsuarioAgrupado(idEmpresa: string, idUsuarioConectado, activo: boolean) {

        let urlFinal = this.urls.obtenerUsuarioAgrupado + `?idEmpresa=${idEmpresa}&idUsuarioConectado=${idUsuarioConectado}&activo=${activo}`;       

        return this.get<JsonResponse>(urlFinal);
    }

    obtenerUsuarioPaginado(tamanioPagina: number, pagina: number, idEmpresa: string, idUsuarioConectado, activo: boolean) {

        let urlFinal = this.urls.obtenerUsuarioPaginado + `?tamanioPagina=${tamanioPagina}&pagina=${pagina}&idEmpresa=${idEmpresa}&idUsuarioConectado=${idUsuarioConectado}&activo=${activo}`;       

        return this.get<JsonResponse>(urlFinal);
    }

    agregarTareaXNota(tareasXNotas: TareasXNotas) {

        return this.post<JsonResponse>(this.urls.agregarTareaXNota, tareasXNotas);
    }

    agregarTareaXDocumento(tareasXDocumento: TareasXDocumento) {

        return this.post<JsonResponse>(this.urls.agregarTareaXDocumento, tareasXDocumento);
    }

    agregarTareas(tareas: Tareas) {

        return this.post<JsonResponse>(this.urls.agregarTareas, tareas);
    }

    actualizarTareas(tareas: Tareas) {

        return this.post<JsonResponse>(this.urls.actualizarTareas, tareas);
    }

    actualizarTareasAdministrador(tareas: Tareas) {

        return this.post<JsonResponse>(this.urls.actualizarTareasAdministrador, tareas);
    }

    obtenerTareasXUsuario(tareas: Tareas) {

        return this.post<JsonResponse>(this.urls.obtenerTareasXUsuario, tareas);
    }

    obtenerTareasXUsuariosAdmin(IdEmpresa: any) {

        let urlFinal = this.urls.obtenerTareasXUsuariosAdmin + `?idEmpresa=${IdEmpresa}`;       

        return this.get<JsonResponse>(urlFinal);
    }

    obtenerTareas(tareas: Tareas) {

        return this.post<JsonResponse>(this.urls.obtenerTareas, tareas);
    }

    eliminarDocumento(tareaXDocumento: TareasXDocumento) {

        return this.post<JsonResponse>(this.urls.eliminarDocumento, tareaXDocumento);
    }

    //#region Metodos Generales
    inicializarCatalogo(catalogo: Array<any>, asignarElementoInicial: Function, id: string, valor: string, tituloPrimerElemento?: string) {

        const elementoInicial = {
            [id]: 0,
            [valor]: tituloPrimerElemento != null ? tituloPrimerElemento : 'Selecciona una opción'
        };

        catalogo.splice(0, catalogo.length);
        catalogo.push(elementoInicial);

        asignarElementoInicial(elementoInicial);
    }

    inicializarCatalogoSoloUnValor(catalogo: Array<any>, asignarElementoInicial: Function, valor: string, tituloPrimerElemento?: string) {

        const elementoInicial = {            
            [valor]: tituloPrimerElemento != null ? tituloPrimerElemento : 'Selecciona una opción'
        };

        catalogo.splice(0, catalogo.length);
        catalogo.push(elementoInicial);

        asignarElementoInicial(elementoInicial);
    }
    //#endregion

}