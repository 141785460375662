

<ng-template #subirImagen let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="modal-title">Sube una nueva imagen</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancelarModalSubirImagen()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-12" style="margin-top: -5px">
      <div class="col-md-12 col-lg-12">
        <!-- <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)" *ngIf="!imageChangedEvent"
          [uploader]="uploader" class="py-5 mb-3 text-center font-medium-5 text-uppercase grey my-drop-zone" (change)="fileChangeEvent($event)">
          Arrasta la imagen aqui
        </div> -->

        <div style="text-align: center;" *ngIf="!imageChangedEvent">
          <label class="font-medium-2 text-uppercase">Arrasta la imagen aqui</label>
        </div>

        <div class="file-container" *ngIf="!imageChangedEvent" >
            <input type="file" class="file" (change)="getFile($event)" style="width:100%"/>              
        </div>

        <br *ngIf="!imageChangedEvent">

        <div style="text-align: center;" *ngIf="!imageChangedEvent">
          <label class="font-medium-2 text-uppercase">o Selecciona tu archivo</label>
        </div>

        <div class="row" *ngIf="!imageChangedEvent">                             
          <div class="custom-file mx-auto d-block col-md-8">
            <input type="file" class="custom-file-input" id="inputGroupFile01" (change)="fileChangeEvent($event)" accept="image/png, image/gif, image/jpeg">
            <label class="custom-file-label" for="inputGroupFile01">Seleccion tu archivo</label>
          </div>
        </div>

        <div class="row" *ngIf="imageChangedEvent" style="text-align: center;">
          <div class="col-md-12" style="margin-top: -5px;">
            <button type="button" class="btn btn-sm btn-primary btn-raised" (click)="rotateLeft()" style="margin-left: 5px; width: 160px;">Gira a la izquierda</button>
            <button type="button" class="btn btn-sm btn-primary btn-raised" (click)="rotateRight()" style="margin-left: 5px; width: 160px;">Gira a la derecha</button>
            <button type="button" class="btn btn-sm btn-primary btn-raised" (click)="flipHorizontal()" style="margin-left: 5px; width: 160px;">Gira en horizontal</button>
            <button type="button" class="btn btn-sm btn-primary btn-raised" (click)="flipVertical()" style="margin-left: 5px; width: 160px;">Gira en vertical</button>
            <button type="button" class="btn btn-sm btn-warning btn-raised" (click)="cancelCropping()" style="margin-left: 5px; width: 180px;">Cancelar Imagen 
              <i class="ft-x position-right"></i>
            </button>    
          </div>
        </div>
        
        <div class="row" *ngIf="imageChangedEvent" style="text-align: center;">
          <div class="col-md-6">
            <div class="form-group">
              <table>
                <tr>
                  <td>
                    <label class="text-uppercase" style="font-size: 10pt;">Rotar</label>
                  </td>
                  <td>
                    <input type="number" class="form-control" min="0" max="360" maxlength="3" style="margin-left: 5px; width: 70px; font-size: 10pt;"
                        [(ngModel)]="rotation" [ngModelOptions]="{standalone: true}" (ngModelChange)="revisarRotation()">
                  </td>
                  <td>
                    <nouislider [min]="0" [max]="360" [step]="1" [(ngModel)]="rotation" (ngModelChange)="updateRotation()" style="width: 300px; margin-top: 50px; margin-left: 5px;">
                    </nouislider>
                  </td>              
                </tr>
              </table>          
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <table>
                <tr>
                  <td>
                    <label class="text-uppercase" style="font-size: 10pt;">Zoom</label>
                  </td>
                  <td>
                    <input type="number" class="form-control" min="-10" max="10" maxlength="2" style="margin-left: 5px; width: 70px; font-size: 10pt;"
                        [(ngModel)]="scale" [ngModelOptions]="{standalone: true}" (ngModelChange)="revisarZoom()">
                  </td>           
                  <td>
                    <nouislider [min]="-10" [max]="10" [step]="0.1" [(ngModel)]="scale" (ngModelChange)="zoomChange()" style="width: 300px; margin-top: 50px; margin-left: 5px;">
                    </nouislider>
                  </td>              
                </tr>
              </table>          
            </div>
          </div>
        </div>

        <div class="row">                               
          <div class="col-md-6" style="margin-top: 10px; text-align: center;">
            <image-cropper [imageChangedEvent]="imageChangedEvent"
                            [maintainAspectRatio]="true" 
                            [aspectRatio]="5 / 10"
                            [resizeToWidth]="512"
                            [resizeToHeight]="400"
                            [cropperMinWidth]="128"
                            [onlyScaleDown]="true"
                            [canvasRotation]="canvasRotation"
                            [transform]="transform"
                            [alignImage]="'left'"
                            format="png" 
                            (imageCropped)="imageCropped($event)" 
                            (imageLoaded)="imageLoaded()"
                            (cropperReady)="cropperReady()" 
                            (loadImageFailed)="loadImageFailed()"
                            [style.display]="showCropper ? null : 'none'">
            </image-cropper>
          </div>
          <br>
          <div class="col-md-6" style="margin-top: 10px; text-align: center;" *ngIf="showCropper">
            <span class="result">
              <img [src]="croppedImage" style="width: 200px; height: 400px;"/>           
            </span>
          </div>
        </div> 
      </div> 
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary btn-raised" (click)="guardarImagen()" [disabled]="!imageChangedEvent">Guardar Imagen</button>
    <button type="button" class="btn btn-sm btn-secondary btn-raised" (click)="cancelarModalSubirImagen()">Cancelar</button>
  </div>
</ng-template>

<!-- Theme customizer Starts-->
<div #customizer class="customizer d-none d-lg-none d-xl-block" [ngClass]="{open: isOpen}">
  <a class="customizer-close" (click)="closeCustomizer()">
    <i class="ft-x font-medium-3"></i></a><a class="customizer-toggle bg-primary" id="customizer-toggle-icon"
    (click)="toggleCustomizer()">
    <i class="fas fa-cog font-medium-1 spinner white align-middle"></i>
  </a>
  <div [perfectScrollbar] class="customizer-content p-3 ps-container ps-theme-dark"
    data-ps-id="df6a5ce4-a175-9172-4402-dabd98fc9c0a">
    <h4 class="text-uppercase">Theme Customizer</h4>
    <p>Customize & Preview in Real Time</p>
    <!-- Layout Options Starts-->
    <div class="ct-layout">
      <hr>
      <h6 class="mb-3 d-flex align-items-center">
        <i class="ft-layout font-medium-2 mr-2"></i>
        <span>Layout Options</span>
      </h6>
      <div class="layout-switch">
        <div class="radio radio-sm d-inline-block light-layout mr-3">
          <input id="ll-switch" type="radio" name="layout-switch" [checked]="config.layout.variant === 'Light'"
            (click)="customizerService.switchLayout('light', isBgImageDisplay)">
          <label for="ll-switch">Light</label>
        </div>
        <div class="radio radio-sm d-inline-block dark-layout mr-3">
          <input id="dl-switch" type="radio" name="layout-switch" [checked]="config.layout.variant === 'Dark'"
            (click)="customizerService.switchLayout('dark', isBgImageDisplay)">
          <label for="dl-switch">Dark</label>
        </div>
        <div class="radio radio-sm d-inline-block transparent-layout">
          <input id="tl-switch" type="radio" name="layout-switch" [checked]="config.layout.variant === 'Transparent'"
            (click)="customizerService.switchLayout('transparent', isBgImageDisplay)">
          <label for="tl-switch">Transparent</label>
        </div>
      </div>
      <!-- Layout Options Ends-->
    </div>
    <!-- Navigation Options Starts-->
    <div class="ct-menu-type">
      <hr>
      <h6 class="mb-3 d-flex align-items-center">
        <i class="ft-credit-card font-medium-2 mr-2"></i>
        <span>Navigation Options</span></h6>
      <div class="menu-switch">
        <div class="radio radio-sm d-inline-block menu-side mr-3">
          <input id="menu-side" type="radio" name="menu-switch" [checked]="config.layout.menuPosition === 'Side'"
            (click)="customizerService.toggleMenuPosition('Side')">
          <label for="menu-side">Vertical</label>
        </div>
        <div class="radio radio-sm d-inline-block menu-top">
          <input id="menu-top" type="radio" name="menu-switch" [checked]="config.layout.menuPosition === 'Top'"
            (click)="customizerService.toggleMenuPosition('Top')">
          <label for="menu-top">Horizontal</label>
        </div>
      </div>
    </div>
    <!-- Sidebar Options Starts-->
    <!-- Navbar Types-->
    <div class="ct-navbar-type">
      <hr>
      <h6 class="mb-3 d-flex align-items-center">
        <i class="ft-more-horizontal font-medium-2 mr-2"></i>
        <span>Navbar Type</span></h6>
      <div class="navbar-switch">
        <div class="radio radio-sm d-inline-block nav-static mr-3">
          <input id="nav-static" type="radio" name="navbar-switch" [checked]="config.layout.navbar.type === 'Static'"
            (click)="customizerService.toggleNavbarType('Static')">
          <label for="nav-static">Static</label>
        </div>
        <div class="radio radio-sm d-inline-block nav-fixed">
          <input id="nav-fixed" type="radio" name="navbar-switch" [checked]="config.layout.navbar.type === 'Fixed'"
            (click)="customizerService.toggleNavbarType('Fixed')">
          <label for="nav-fixed">Fixed</label>
        </div>
      </div>
    </div>
    <!-- Sidebar Options Starts-->
    <div class="ct-bg-color">
      <hr>
      <h6 class="sb-options d-flex align-items-center mb-3">
        <i class="ft-droplet font-medium-2 mr-2"></i>
        <span>Sidebar Color Options</span></h6>
      <div class="cz-bg-color sb-color-options">
        <div class="row mb-3">
          <div class="col px-2" *ngFor="let color of customizerService.lightDarkLayoutGradientBGColors">
            <span class="{{color.class}} d-block rounded" style="width:30px; height:30px;"
              [attr.data-bg-color]="color.code" [ngClass]="{'selected': color.active}"
              (click)="customizerService.changeSidebarBGColor(color)">
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col px-2" *ngFor="let color of customizerService.lightDarkLayoutSolidBGColors">
            <span class="{{color.class}} d-block rounded" style="width:30px; height:30px;"
              [attr.data-bg-color]="color.code" [ngClass]="{'selected': color.active}"
              (click)="customizerService.changeSidebarBGColor(color)">
            </span>
          </div>
        </div>
      </div>
      <!-- Sidebar Options Ends-->
      <!-- Transparent BG Image Ends-->
      <div class="tl-bg-img">
        <h6 class="d-flex align-items-center mb-3">
          <i class="ft-star font-medium-2 mr-2"></i>
          <span>Background Colors with Shades</span></h6>
        <div class="cz-tl-bg-image row">

          <div class="col-sm-3" *ngFor="let color of customizerService.transparentLayoutBGColorsWithShades">
            <div class="rounded {{color.class}} ct-glass-bg" [attr.data-bg-image]="color.class"
              [ngClass]="{'selected': color.active}" (click)="customizerService.changeSidebarTransparentBGColor(color)">
            </div>
          </div>
        </div>
      </div>
      <!-- Transparent BG Image Ends-->
    </div>
    <!-- Sidebar BG Image Starts-->
    <div class="ct-bg-image">
      <hr>
      <h6 class="sb-bg-img d-flex align-items-center mb-3">
        <i class="ft-sidebar font-medium-2 mr-2"></i>
        <span>Sidebar Bg Image</span>
      </h6>
      <div class="cz-bg-image row sb-bg-img" *ngIf="authService.usuarioConectado != null">
        <div class="col-2 px-2" *ngFor="let imagen of authService.usuarioConectado.ListaCatalogoConfiguracionImagenes">
          <img class="rounded" alt="sidebar bg image"
           [src]="imagen.Ruta" width="90"  
           [ngClass]="{'selected': imagen.Active}" 
          (click)="customizerService.changeSidebarBgImage(imagen)" />
        </div>
        <div class="col-2 px-2" style="margin-top: 10px;" *ngFor="let imagen of authService.usuarioConectado.ListaEmpresaConfiguracionImagenes">
          <div class="avatar bg-primary mr-2" style="margin-top: 30px; margin-bottom: 20px;">
            <div class="avatar-content" style="width:100%">          
              <img class="rounded" alt="sidebar bg image"
              [src]="imagen.Ruta" width="90"  
              [ngClass]="{'selected': imagen.Active}"  
              (click)="customizerService.changeSidebarBgImage(imagen)" />
              <span class="badge badge-pill badge-round badge-danger" style="top: -30px" (click)="borrarImagen(imagen)">X</span>
            </div>
          </div>    
        </div>
        <!-- <div class="col-2 px-2" *ngFor="let img of customizerService.lightDarkLayoutBGImages">
          <img class="rounded" alt="sidebar bg image" [src]="img.src" width="90" [ngClass]="{'selected': img.active}"
            (click)="customizerService.changeSidebarBgImage(img)" />
        </div> -->

        <br />

        <button type="reset" style="margin-top: 10px;" *ngIf="authService.usuarioConectado != null && authService.usuarioConectado.Usuario != null"
              (click)="openModalSubirImagen(subirImagen)" [disabled]="authService.usuarioConectado.ListaEmpresaConfiguracionImagenes == null 
              || authService.usuarioConectado.ListaEmpresaConfiguracionImagenes.length == 6"
              class="btn btn-primary form-control-sm d-inline-block width-350">
          Subir nueva imagen <i class="fas fa-cloud-upload-alt position-right"></i>
        </button>
      </div>
      

      <!-- Transparent Layout Bg color Options-->
      <div class="tl-color-option">
        <h6 class="tl-color-options d-flex align-items-center mb-3">
          <i class="ft-droplet font-medium-2 mr-2"></i>
            <span>Background Colors</span></h6>
        <div class="cz-tl-bg-color">
          <div class="row">
            <div class="col" *ngFor="let color of customizerService.transparentLayoutBGColors">
              <span class="{{color.class}} d-block rounded  ct-color-bg" style="width:30px; height:30px;"
                [attr.data-bg-color]="color.class" [ngClass]="{'selected': color.active}"
                (click)="customizerService.changeSidebarTransparentBGColor(color)">
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- Transparent Layout Bg color Ends-->
    </div>
    <!-- Sidebar BG Image Toggle Starts-->
    <div class="ct-bg-image-toggler">
      <div class="togglebutton toggle-sb-bg-img">
        <hr>
        <div>
          <span>Sidebar Bg Image</span>
          <div class="float-right">
            <div class="checkbox">
              <input class="cz-bg-image-display" id="sidebar-bg-img" type="checkbox" checked
                (change)="bgImageDisplay($event)">
              <label for="sidebar-bg-img"></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Sidebar BG Image Toggle Ends-->
    <!-- Compact Menu Starts-->
    <!-- <div class="ct-compact-toggler">
      <hr>
      <div class="togglebutton">
        <div>
          <span>Compact Menu</span>
          <div class="float-right">
            <div class="checkbox">
              <input class="cz-compact-menu" id="cz-compact-menu" type="checkbox"
                [checked]="config.layout.sidebar.collapsed" (change)="customizerService.toggleCompactMenu($event)">
              <label for="cz-compact-menu"></label>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Compact Menu Ends-->

    <!-- Sidebar Width Starts-->
    <div class="ct-sidebar-size" *ngIf="!config.layout.sidebar.collapsed">
      <hr>
      <p>Sidebar Width</p>
      <div class="cz-sidebar-width btn-group btn-group-toggle" id="cz-sidebar-width" data-toggle="buttons">
        <label class="btn btn-outline-primary" [ngClass]="{'active': size === 'sidebar-sm'}">
          <input id="cz-btn-radio-1" type="radio" name="cz-btn-radio" value="sidebar-sm"
            [checked]="size === 'sidebar-sm'" (change)="changeSidebarWidth('sidebar-sm')"><span>Small</span>
        </label>
        <label class="btn btn-outline-primary " [ngClass]="{'active': size === 'sidebar-md'}">
          <input id="cz-btn-radio-2" type="radio" name="cz-btn-radio" value="sidebar-md"
            [checked]="size === 'sidebar-md'" (change)="changeSidebarWidth('sidebar-md')"><span>Medium</span>
        </label>
        <label class="btn btn-outline-primary" [ngClass]="{'active': size === 'sidebar-lg'}">
          <input id="cz-btn-radio-3" type="radio" name="cz-btn-radio" value="sidebar-lg"
            [checked]="size === 'sidebar-lg'" (change)="changeSidebarWidth('sidebar-lg')"><span>Large</span>
        </label>
      </div>
    </div>
    <!-- Sidebar Width Ends-->

    <br />
    <button type="reset" 
            (click)="guardarCambios()"
            class="btn btn-primary form-control-sm d-inline-block width-350">
    Guardar cambios <i class="fas fa-save position-right"></i>
    </button>
  </div>
</div>
<!-- Theme customizer Ends-->
