import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-web-storage';
import { AuthService } from '../auth/auth.service';
import { ConfigService } from '../services/config.service';

@Injectable({
    providedIn: 'root'
})
export class InactivityService {
    private timeoutId: any;
    private readonly timeout: number = 15 * 60 * 1000; // 15 minutos

    constructor(private router: Router, private ngZone: NgZone,
        public local: LocalStorageService, public authService: AuthService, private configService: ConfigService) {
        this.startTimer();
        this.setupListeners();
    }

    private startTimer() {
        this.ngZone.runOutsideAngular(() => {
            this.timeoutId = setTimeout(() => this.logout(), this.timeout);
        });
    }

    private resetTimer() {
        clearTimeout(this.timeoutId);
        this.startTimer();
    }

    private setupListeners() {
        document.addEventListener('mousemove', () => this.resetTimer());
        document.addEventListener('keydown', () => this.resetTimer());
        document.addEventListener('click', () => this.resetTimer());
    }

    private logout() {
        this.ngZone.run(() => {            
            this.router.navigate(['/inicio/login']);
        });
    }
}