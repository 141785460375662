import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SignalRService } from './shared/services/signal-r.service';
import { InactivityService } from './shared/services/inactivity.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;

    constructor(private router: Router, 
        public signalRService: SignalRService,
        private inactivityService: InactivityService) {         
    }

    ngOnInit() {

        // this.subscription = this.router.events.subscribe((event: NavigationEnd) => {
        //     ////console.log("event");
        //     ////console.log(event);
        //     this.signalRService.currentRoute = event.url;
        // });

        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe((event: NavigationEnd) => {
                window.scrollTo(0, 0);
                ////console.log("event");
                //////console.log(event);
                this.signalRService.currentRoute = event.url;
                ////console.log(this.signalRService.currentRoute);

            });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



}